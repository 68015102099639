import { Minus, Plus } from '@components/icons'
import cn from 'clsx'
import { useTranslation } from 'next-i18next'
import React, { FC } from 'react'
import s from './Quantity.module.css'
export interface QuantityProps {
  value: number
  increase: () => any
  decrease: () => any
  handleRemove?: React.MouseEventHandler<HTMLButtonElement>
  handleChange?: React.ChangeEventHandler<HTMLInputElement>
  max?: number
  classNames?: string
  allowZero?: boolean
}

const Quantity: FC<QuantityProps> = ({
  value,
  increase,
  decrease,
  handleChange,
  handleRemove,
  allowZero,
  max,
  classNames,
}) => {
  const { t } = useTranslation('common')
  return (
    <div className={cn('space-around flex flex-row items-center', classNames)}>
      <button
        type="button"
        onClick={decrease}
        className={s.actions}
        style={{ marginLeft: '-1px' }}
        disabled={allowZero ? value == 0 : value <= 1}
      >
        <Minus width={18} height={18} />
      </button>
      <div className={s.input}>{value}</div>

      <button
        type="button"
        onClick={increase}
        className={cn(s.actions)}
        style={{ marginLeft: '-1px' }}
        disabled={value >= max!}
      >
        <Plus width={18} height={18} />
      </button>
      {handleRemove && (
        <button
          className={cn(s.actions, 'text-decoration-line: ml-auto underline')}
          onClick={handleRemove}
        >
          {t('remove')}
        </button>
      )}
    </div>
  )
}

export default Quantity
