import { List } from '../types';
import { getListConfig } from './getListConfig';

export default async function getTempListId(locale?: string) {
  const listTempStorageName = getListConfig(locale!).listTempStorageName;
  const tempListId = localStorage.getItem(listTempStorageName);
  if (tempListId) {
    return tempListId;
  }
  if (!tempListId) {
    try {
      const endPoint = `/api/list?locale=${locale}`;
      const response = await fetch(endPoint, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        const result: { list: List } = await response.json();
        localStorage.setItem(listTempStorageName, result.list.id);
        return result.list.id;
      }
    } catch (err) {
      console.log(err);
    }
  }
}
