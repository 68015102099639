import { useAcceptCookies } from '@lib/hooks/useAcceptCookies'
import { getConfigByLocale } from '@lib/locale'
import {
  type VisitorConsentCollected,
  useCustomerPrivacy,
} from '@shopify/hydrogen'
import { useRouter } from 'next/router'
import { Fragment, useEffect } from 'react'

export default function ShopifyCookieConsent() {
  const { locale } = useRouter()
  const { acceptedCookies, onAcceptCookies } = useAcceptCookies(
    'bauwerk_cookie_consent'
  )
  // const [bannerLoaded, setBannerLoaded] = useState(false)
  const storefrontAccessToken = getConfigByLocale(locale, 'shopifyAPIToken')
  const shopifyDomain = getConfigByLocale(locale, 'shopifyDomain')
  const { customerPrivacy, privacyBanner = null } = useCustomerPrivacy({
    storefrontAccessToken,
    checkoutDomain: shopifyDomain,
    onVisitorConsentCollected: (consent: VisitorConsentCollected) => {
      console.log('Visitor consent collected:', consent)
      onAcceptCookies()
    },
  })

  const language = getConfigByLocale(locale, 'language')
  const countryCode = getConfigByLocale(locale, 'defaultCurrencyCountryCode')

  useEffect(() => {
    if (customerPrivacy) {
      // check if user has marketing consent
      // console.log(
      //   'User marketing consent:',
      //   customerPrivacy.analyticsProcessingAllowed()
      // )
      // or set tracking consent
      // customerPrivacy.setTrackingConsent(
      //   {
      //     marketing: true,
      //     analytics: true,
      //     preferences: true,
      //     sale_of_data: true,
      //   },
      //   (data: any) => {
      //     if (data?.error) {
      //       console.error('Error setting tracking consent:', data.error)
      //       return
      //     }
      //     console.log('Tracking consent set')
      //   }
      // )
    }

    if (privacyBanner && !acceptedCookies) {
      //privacyBanner.loadBanner()
      console.log('privacy banner loaded.')
      // or show banner with specific locale and country
      privacyBanner.loadBanner({ locale: language, country: countryCode })
      // or show consent preferences banner
      // privacyBanner.showPreferences()

      // or show consent preferences banner with specific locale and country
      // privacyBanner.showPreferences({locale: 'FR', country: 'CA'});
    }
  }, [customerPrivacy, privacyBanner, language, countryCode, acceptedCookies])

  return (
    <Fragment>
      <style jsx global>
        {`
          .shopify-pc__banner__dialog:nth-child(2) {
            display: none !important;
          }
        `}
      </style>
    </Fragment>
  )
}
