import { ChevronDown, Location } from '@components/icons'
import { useUI } from '@components/ui'
import processLocale, {
  LANGUAGE_OPTIONS,
  LOCALE_SWITCHER,
  REGION_OPTIONS,
} from '@lib/locale'
import cn from 'clsx'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { isMobile } from 'react-device-detect'
interface Props {
  displayText?: string
  iconsColour?: string
  className?: string
}
const CountrySelectorButton = ({
  displayText,
  iconsColour,
  className,
}: Props) => {
  const { t } = useTranslation('common')
  const { setModalView, openModal, setSidebarView, openSidebar } = useUI()
  const { locale } = useRouter()

  const handleShowCS = (currentWidth: number) => {
    if (currentWidth < 768) {
      setSidebarView('COUNTRY_SELECTOR_VIEW')
      openSidebar()
      // TODO: show mobile version
    } else {
      setModalView('COUNTRY_SELECTOR_VIEW')
      openModal()
    }
  }

  const showCountrySelector = () => {
    if (isMobile) {
      setSidebarView('COUNTRY_SELECTOR_VIEW')
      openSidebar()
    } else {
      setModalView('COUNTRY_SELECTOR_VIEW')
      openModal()
    }
  }
  const currentLocale = processLocale(locale)

  const currentItem = LOCALE_SWITCHER.find(
    (item) => item.value === currentLocale
  )!
  const currentRegionOption = REGION_OPTIONS.find(
    (option) => option.value === currentItem.region
  )!
  const currentLanguageOption = LANGUAGE_OPTIONS.find(
    (option) => option.value === currentItem.language
  )!
  const regionLanguageCount = LOCALE_SWITCHER.filter(
    (item) => item.region === currentRegionOption.value
  ).length

  const i18nDisplayText = `${t(displayText?.toLowerCase() ?? '')}`

  return (
    <>
      <button
        className={cn(
          'flex items-center justify-center gap-2',
          {
            'text-brand-dark-grey': iconsColour === '#646464',
            'text-white': iconsColour === 'white',
          },
          className
        )}
        // aria-label={`${t('language-selector')}`}
        // Note: whatever reason on first page load this translation does not work
        aria-label="Language Selector"
        onClick={showCountrySelector}
      >
        <Location fill={iconsColour} />
        {i18nDisplayText ? (
          <div className="body-2 text-decoration-line: text-brand-dark-grey underline">
            {i18nDisplayText}
          </div>
        ) : (
          <>
            {currentRegionOption.value.toUpperCase()}{' '}
            {regionLanguageCount > 1 && `/ ${currentLanguageOption.name}`}
            <ChevronDown fill={iconsColour} />
          </>
        )}
      </button>
    </>
  )
}

export default CountrySelectorButton
