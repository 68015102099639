const Maestro = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="24"
      fill="none"
      viewBox="0 0 38 24"
      {...props}
    >
      <g clipPath="url(#clip0_5250_96447)">
        <path
          fill="#fff"
          stroke="#EDEDED"
          d="M4 .5h30A3.5 3.5 0 0137.5 4v16a3.5 3.5 0 01-3.5 3.5H4A3.5 3.5 0 01.5 20V4A3.5 3.5 0 014 .5z"
        ></path>
        <path fill="#EB001B" d="M15.2 19.2a7 7 0 100-14 7 7 0 000 14z"></path>
        <path fill="#00A2E5" d="M23.2 19.2a7 7 0 100-14 7 7 0 000 14z"></path>
        <path
          fill="#7375CF"
          d="M22.2 12.2c0-2.4-1.2-4.5-3-5.7-1.8 1.3-3 3.4-3 5.7 0 2.3 1.2 4.5 3 5.7 1.8-1.2 3-3.3 3-5.7z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_5250_96447">
          <path fill="#fff" d="M0 0H38V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default Maestro
