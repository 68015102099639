import ContentLoader from 'react-content-loader'
interface LoaderProps {
  title: string
}

const UpsellItemLoader = ({ title }: LoaderProps) => (
  <ContentLoader
    uniqueKey={title.split(' ').join().toLowerCase().replace(/,/g, '')}
    speed={3}
    width={300}
    height={88}
    viewBox="0 0 300 88"
    backgroundColor="#DDDDDD"
    foregroundColor="#000000"
  >
    <rect x="12" y="12" rx="0" ry="0" width="68" height="64" />
    <rect x="102" y="28" rx="2" ry="2" width="140" height="10" />
    <rect x="102" y="50" rx="2" ry="2" width="140" height="10" />
    <rect x="272" y="28" rx="0" ry="0" width="65" height="32" />
  </ContentLoader>
)

export default UpsellItemLoader
