const Account = ({ ...props }) => {
  return (
    <svg
      width={16}
      height={19}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.2 10.286c-1.025 0-1.518.571-3.2.571-1.682 0-2.171-.571-3.2-.571-2.65 0-4.8 2.15-4.8 4.8v1.485c0 .947.768 1.715 1.714 1.715h12.572c.946 0 1.714-.768 1.714-1.715v-1.485c0-2.65-2.15-4.8-4.8-4.8Zm3.657 6.285a.573.573 0 0 1-.571.572H1.714a.573.573 0 0 1-.571-.572v-1.485A3.66 3.66 0 0 1 4.8 11.429c.7 0 1.396.571 3.2.571 1.8 0 2.5-.571 3.2-.571a3.66 3.66 0 0 1 3.657 3.657v1.485ZM8 9.143A4.571 4.571 0 1 0 8 0a4.571 4.571 0 0 0 0 9.142Zm0-8c1.89 0 3.429 1.54 3.429 3.428C11.429 6.461 9.889 8 8 8a3.434 3.434 0 0 1-3.429-3.429c0-1.889 1.54-3.428 3.429-3.428Z"
        fill={props?.fill || '#646464'}
      />
    </svg>
  )
}

export default Account
