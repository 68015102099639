import { Facebook, Instagram, Pinterest, Youtube } from '@components/icons'
interface Props {
  width?: number
  height?: number
  fill?: string
}
const SocialIcons = ({ width, height, fill }: Props) => {
  const socialData = {
    facebook: {
      link: 'https://www.facebook.com/BauwerkColour/',
    },
    instagram: {
      link: 'https://www.instagram.com/bauwerkcolour/',
    },
    youtube: {
      link: 'https://www.youtube.com/channel/UCMMPRCMM6M1kDh614-iiB5g',
    },
    pinterest: {
      link: 'https://www.pinterest.com.au/bauwerkcolour/',
    },
  }
  const { youtube, instagram, facebook, pinterest } = socialData
  return (
    <div className="flex gap-4">
      {youtube?.link && (
          <a
            href={youtube?.link || '/'}
            target="_blank"
            rel="noreferrer"
            className="hover:opacity-75"
          >
            <Youtube width={width} height={height} fill={fill} />
          </a>
      )}

      {instagram?.link && (
          <a
            href={instagram?.link || '/'}
            target="_blank"
            rel="noreferrer"
            className="hover:opacity-75"
          >
            <Instagram width={width} height={height} fill={fill} />
          </a>
      )}

      {facebook?.link && (
          <a
            href={facebook?.link || '/'}
            target="_blank"
            rel="noreferrer"
            className="hover:opacity-75"
          >
            <Facebook width={width} height={height} fill={fill} />
          </a>
      )}

      {pinterest?.link && (
          <a
            href={pinterest?.link || '/'}
            target="_blank"
            rel="noreferrer"
            className="hover:opacity-75"
          >
            <Pinterest width={width} height={height} fill={fill} />
          </a>
      )}
    </div>
  )
}
export default SocialIcons
