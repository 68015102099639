import { useUI } from '@components/ui'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

type Props = {
  lineItems: any[]
  rule: any
}

function CartSidebarMessage({ lineItems, rule }: Props) {
  const { t } = useTranslation('common')
  const [showMessage, setShowMessage] = useState(false)
  const { closeSidebarIfPresent } = useUI()
  const { push } = useRouter()
  useEffect(() => {
    if (lineItems.length > 0 && rule) {
      let passedRule = true
      let ruleValueSet: any = {}
      rule?.condition.forEach((element: any) => {
        ruleValueSet[element.attribute] = []
      })
      lineItems.forEach((item) => {
        for (const key in ruleValueSet) {
          if (Array.isArray(item[key])) ruleValueSet[key].push(...item[key])
          else ruleValueSet[key].push(item[key])
        }
      })

      rule?.condition.forEach((element: any) => {
        let passed = false
        element.values.forEach((value: any) => {
          passed ||= ruleValueSet[element.attribute].includes(value)
        })
        passedRule &&= element.has === passed
      })
      setShowMessage(passedRule)
    }
  }, [lineItems, rule])

  const goToLink = () => {
    push(rule?.link?.url || '/')
    closeSidebarIfPresent()
  }

  return showMessage ? (
    <div className="mt-2 mb-8 border-l-4 border-l-gray-700 bg-white py-2 pl-4 pr-3 text-body2">
      {t(rule?.messageKey)}{' '}
      {rule?.link && (
        <button onClick={goToLink} className="border-b font-thin">
          {t(rule?.link.textKey)}
        </button>
      )}
    </div>
  ) : null
}

export default CartSidebarMessage
