const Cart = ({ ...props }) => {
  return (
    <svg
      width={18}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.75 5c0-2.757-2.243-5-5-5s-5 2.243-5 5H0v11.875C0 18.601 1.4 20 3.125 20h11.25c1.726 0 3.125-1.4 3.125-3.125V5h-3.75Zm-5-3.75A3.754 3.754 0 0 1 12.5 5H5a3.754 3.754 0 0 1 3.75-3.75Zm7.5 15.625a1.877 1.877 0 0 1-1.875 1.875H3.125a1.877 1.877 0 0 1-1.875-1.875V6.25h2.5v1.875a.625.625 0 1 0 1.25 0V6.25h7.5v1.875a.625.625 0 1 0 1.25 0V6.25h2.5v10.625Z"
        fill={props?.fill || '#646464'}
      />
    </svg>
  )
}

export default Cart
