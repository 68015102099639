const Close = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.04887 6.34313C6.8536 6.14787 6.53702 6.14787 6.34176 6.34313C6.1465 6.53839 6.1465 6.85498 6.34176 7.05024L11.2915 12L6.34176 16.9497C6.1465 17.145 6.1465 17.4616 6.34176 17.6568C6.53702 17.8521 6.8536 17.8521 7.04887 17.6568L11.9986 12.7071L16.9484 17.6568C17.1436 17.8521 17.4602 17.8521 17.6555 17.6568C17.8507 17.4616 17.8507 17.145 17.6555 16.9497L12.7057 12L17.6555 7.05024C17.8507 6.85498 17.8507 6.53839 17.6555 6.34313C17.4602 6.14787 17.1436 6.14787 16.9484 6.34313L11.9986 11.2929L7.04887 6.34313Z"
        fill="#646464"
      />
    </svg>
  )
}

export default Close
