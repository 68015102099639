import { Cart } from '@commerce/types'
import { Button, useUI } from '@components/ui'
import useCustomer from '@lib/hooks/useCustomer'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
interface Props {
  cart: Cart.Cart
  checkoutUrl: string | undefined
}
const CheckoutButton = ({ cart, checkoutUrl }: Props) => {
  const { t } = useTranslation('common')
  const { customer: customerData, isB2B } = useCustomer()
  const router = useRouter()
  const { closeSidebar } = useUI()
  // const { hits, sendEvent } = useHits()

  const handleCheckoutClick = () => {
    if (
      customerData &&
      isB2B &&
      (!customerData.firstName || !customerData.lastName)
    ) {
      router.push({
        pathname: '/me',
        query: { redirectReason: 'profile-incomplete' },
      })
      closeSidebar()
    } else if (checkoutUrl) {
      window.location.href = checkoutUrl
    } else {
      console.error('Checkout URL is undefined.')
    }
  }

  return (
    <div>
      <Button
        className="rounded-sm text-label font-normal"
        onClick={handleCheckoutClick}
        width="100%"
      >
        {t('checkout')}
      </Button>
    </div>
  )
}
export default CheckoutButton
