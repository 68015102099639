import { useMutation } from '@apollo/client'
import commerceConfig from '@commerce/config'
import usePrice from '@commerce/product/use-price'
import type { LineItem } from '@commerce/types/cart'
import {
  cartLinesRemoveMutation,
  cartLinesRemoveVars,
  cartLinesUpdateMutation,
  cartLinesUpdateVars,
} from '@commerce/utils'
import { Quantity } from '@components/ui'
import { useUI } from '@components/ui/context'
import {
  PARENT_PRODUCT_CUSTOM_ATTRIBUTE_KEY,
  PRODUCT_TYPE_BUNDLE,
  PRODUCT_TYPE_TOOLS,
  RETAIL_PRICE_CUSTOM_ATTRIBUTE_KEY,
  findcustomAttributeByKey,
} from '@lib/helpers/general'
import processLocale from '@lib/locale'
import cn from 'clsx'
import { useTranslation } from 'next-i18next'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ChangeEvent, useEffect, useState } from 'react'
import s from './CartItem.module.css'
type ItemOption = {
  name: string
  nameId: number
  value: string
  valueId: number
}

const placeholderImg = '/product-img-placeholder.svg'

const CartItem = ({
  item,
  variant = 'default',
  currencyCode,
  ...rest
}: {
  variant?: 'default' | 'display'
  item: LineItem
  currencyCode: string
}) => {
  const { locale } = useRouter()
  const {
    config: { context },
  } = commerceConfig
  const currentLocale = processLocale(locale)
  const { t } = useTranslation('common')
  const currentContext = context?.[currentLocale]
  const taxAmount = currentContext?.taxAmount
  const { closeSidebarIfPresent } = useUI()
  const [removing, setRemoving] = useState(false)
  const [quantity, setQuantity] = useState<number>(item.quantity)
  const [removeItem] = useMutation(cartLinesRemoveMutation, {
    onCompleted() {
      setRemoving(false)
    },
    onError() {
      setRemoving(false)
    },
  })

  const [updateItem] = useMutation(cartLinesUpdateMutation, {
    onCompleted() {
      setRemoving(false)
    },
    onError() {
      setRemoving(false)
    },
  })
  const { price } = usePrice({
    amount: item.totalAmount,
    currencyCode,
    suffix: `(${t('excl-vat')})`,
  })
  const { price: afterTaxPrice } = usePrice({
    amount: item.totalAmount * (1 + taxAmount || 1),
    currencyCode,
    suffix: `(${t('incl-vat-approx')})`,
  })
  const itemCustomAttributes = item.customAttributes?.map(({ key, value }) => ({
    key,
    value,
  }))
  const handleChange = async ({
    target: { value },
  }: ChangeEvent<HTMLInputElement>) => {
    setQuantity(Number(value))
    updateItem({
      variables: cartLinesUpdateVars({
        locale,
        id: item.id,
        quantity: Number(value),
        ...(item.customAttributes?.length && {
          attributes: itemCustomAttributes,
        }),
      }),
      context: { locale },
    })
  }

  const increaseQuantity = async (n = 1) => {
    const val = Number(quantity) + n
    setQuantity(val)
    updateItem({
      variables: cartLinesUpdateVars({
        locale,
        id: item.id,
        quantity: val,
        ...(item.customAttributes?.length && {
          attributes: itemCustomAttributes,
        }),
      }),
      context: { locale },
    })
  }

  const handleRemove = async () => {
    setRemoving(true)
    try {
      removeItem({
        variables: cartLinesRemoveVars({ locale, itemIds: [item.id] }),
        context: { locale },
      })
    } catch (error) {
      setRemoving(false)
    }
  }

  // TODO: Add a type for this
  const options = (item as any).options
  useEffect(() => {
    // Reset the quantity state if the item quantity changes
    if (item.quantity !== Number(quantity)) {
      setQuantity(item.quantity)
    }
    // TODO: currently not including quantity in deps is intended, but we should
    // do this differently as it could break easily
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.quantity])

  const handleQtyIncrease = () => increaseQuantity(1)
  const handleQtyDecrease = () => increaseQuantity(-1)
  const isToolsProduct = item?.type === PRODUCT_TYPE_TOOLS
  const isBundleProduct = item?.type === PRODUCT_TYPE_BUNDLE
  const productLink =
    isToolsProduct || isBundleProduct
      ? item?.path
      : findcustomAttributeByKey(
          item?.customAttributes!,
          PARENT_PRODUCT_CUSTOM_ATTRIBUTE_KEY
        )

  const formattedOriginalPrice = findcustomAttributeByKey(
    item?.customAttributes!,
    RETAIL_PRICE_CUSTOM_ATTRIBUTE_KEY
  )

  const originalPrice = formattedOriginalPrice
    ? Number(formattedOriginalPrice.replace(/[^0-9\.-]+/g, ''))
    : 0

  let i18nItemName = item?.name

  return (
    <>
      <li
        className={cn(s.root, {
          'pointer-events-none opacity-50': removing,
        })}
        {...rest}
      >
        <div className="flex flex-row">
          <div className="h-20 w-20">
            <Link href={`/product/${productLink}`} passHref>
              <Image
                className="rounded-sm"
                onClick={() => closeSidebarIfPresent()}
                src={item.variant.image?.url || placeholderImg}
                alt={item.variant.image?.altText || 'Product Image'}
                width={80}
                layout="fixed"
                height={80}
              />
            </Link>
          </div>
          <div className="ml-3 flex flex-grow flex-col">
            <div className="flex w-full items-start justify-between gap-2">
              <div className="basis-2/5">
                <Link href={`/product/${productLink}`}>
                  <span
                    className={s.productName}
                    onClick={() => closeSidebarIfPresent()}
                  >
                    {i18nItemName}
                  </span>
                </Link>
              </div>

              <div className="flex basis-3/5 flex-col">
                <div className="w-full text-right text-body2 font-medium text-brand-dark-grey ">
                  {price}
                </div>
                {originalPrice &&
                originalPrice > 0 &&
                originalPrice > Number(item.variant.price) ? (
                  <del className="w-full text-right text-body2 font-medium text-brand-dark-grey">
                    {formattedOriginalPrice}
                  </del>
                ) : null}
                {taxAmount && (
                  <div className="w-full text-right text-body2 font-medium text-brand-dark-grey">
                    {afterTaxPrice}
                  </div>
                )}
              </div>
            </div>
            {options && options.length > 0 && (
              <div className="flex w-56 flex-wrap pb-3 pt-1">
                {options.map((option: ItemOption, i: number) => (
                  <div
                    key={`${item.id}-${t(option.name)}`}
                    className="inline-flex items-center justify-center text-body2 font-normal text-brand-dark-grey opacity-50"
                  >
                    {`${t(option.name)}` + `:`}
                    <span className="ml-1 mr-3 inline-flex items-center justify-center overflow-hidden">
                      {option.value}
                    </span>
                  </div>
                ))}
              </div>
            )}
            {variant === 'default' && (
              <div className="mt-auto">
                <Quantity
                  value={quantity}
                  handleRemove={handleRemove}
                  handleChange={handleChange}
                  increase={handleQtyIncrease}
                  decrease={handleQtyDecrease}
                />
              </div>
            )}
          </div>
        </div>
      </li>
    </>
  )
}

export default CartItem
