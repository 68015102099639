import { Cart, Product } from '@commerce/types'

export const SAMPLE_GARDEN_POT_UPSELL_PRODUCTS = ['block-brush']
export const PAINT_UPSELL_PRODUCTS = ['brush-copper-base']
export const PREP_COAT_UPSELL_PRODUCTS = ['lime-wash-prep-coat']

const SAMPLE_GARDENPOT_TYPE = ['Garden Pot Paint', 'Sample Pot']
const PAINT_PRODUCT_TYPE = ['Paint']
const PREP_COAT_PRODUCT_TYPE = ['Sample Pot', 'Paint']

export const getUpsellProdutsByCart = (cart: Cart.Cart) => {
  const upsellProducts: string[] = []
  let processedItems: string[] = []

  cart.lineItems.forEach((item) => {
    if (SAMPLE_GARDENPOT_TYPE.includes(item.type)) {
      processedItems.push(...SAMPLE_GARDEN_POT_UPSELL_PRODUCTS)
    }
    if (PAINT_PRODUCT_TYPE.includes(item.type)) {
      processedItems.push(...PAINT_UPSELL_PRODUCTS)
    }

    // Check for custom attribute _selectedSurfaceRequiresPrepCoatUpsell
    const requiresPrepCoat = item?.customAttributes?.some(
      (attr) =>
        attr.key === '_selectedSurfaceRequiresPrepCoatUpsell' &&
        attr.value === 'true'
    )
    if (PREP_COAT_PRODUCT_TYPE.includes(item.type) && requiresPrepCoat) {
      processedItems.push(...PREP_COAT_UPSELL_PRODUCTS)
    }
  })

  // unique items only
  processedItems = processedItems.filter((v, i, a) => a.indexOf(v) == i)
  processedItems.forEach((h) => {
    const alreadyExist = checkUpsellProductExistsForCart(cart, [h])
    if (alreadyExist.length === 0) upsellProducts.push(h)
  })

  return upsellProducts
}

export const getUpsellProdutsByProduct = (product: Product.Product) => {
  let upsellProducts = []

  if (product?.productType) {
    if (SAMPLE_GARDENPOT_TYPE.includes(product.productType)) {
      upsellProducts.push(...SAMPLE_GARDEN_POT_UPSELL_PRODUCTS)
    }
    if (PAINT_PRODUCT_TYPE.includes(product.productType)) {
      upsellProducts.push(...PAINT_UPSELL_PRODUCTS)
    }
    if (PREP_COAT_PRODUCT_TYPE.includes(product.productType)) {
      upsellProducts.push(...PREP_COAT_UPSELL_PRODUCTS)
    }
  }

  upsellProducts = upsellProducts.filter((v, i, a) => a.indexOf(v) == i)
  return upsellProducts
}

export const checkUpsellProductExistsForCart = (
  cart: Cart.Cart,
  upsellItem: string[]
) => {
  const upsellProductExists = cart?.lineItems.filter((item) => {
    return (
      upsellItem?.includes(item.path) &&
      !PREP_COAT_UPSELL_PRODUCTS.includes(item.path)
    )
  })
  return upsellProductExists
}

export const checkUpsellProductExists = (
  cart: Cart.Cart,
  upsellItem: string[]
) => {
  const upsellProductExists = cart?.lineItems.filter((item) => {
    return upsellItem?.includes(item.path)
  })
  return upsellProductExists
}

export const checkUpsellProductExistsByVariant = (
  cart: Cart.Cart,
  variantId: string | number
) => {
  const upsellProductExists = cart?.lineItems.filter(
    (item) => variantId === item.variantId
  )
  return upsellProductExists
}
