import { gql } from '@apollo/client'

const cartBuyerIdentityUpdate = gql`
  mutation cartBuyerIdentityUpdate($buyerIdentity: CartBuyerIdentityInput!, $cartId: ID!,$country: CountryCode!) @inContext(country: $country) {
    cartBuyerIdentityUpdate(buyerIdentity: $buyerIdentity, cartId: $cartId) {
      cart {
        id
        updatedAt
        createdAt
        checkoutUrl
        buyerIdentity {
          countryCode
          purchasingCompany{
            company{
              id
              name
            }
            location {
              id
              name
            }
          }
        }
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`
export default cartBuyerIdentityUpdate
