const SearchGlass = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.8761 19.9304L16.3411 15.3955C16.2603 15.3146 16.1548 15.2724 16.0423 15.2724H15.6802C16.886 13.9682 17.6243 12.2281 17.6243 10.3121C17.6243 6.27289 14.3514 3 10.3121 3C6.27289 3 3 6.27289 3 10.3121C3 14.3514 6.27289 17.6243 10.3121 17.6243C12.2281 17.6243 13.9682 16.886 15.2724 15.6838V16.0423C15.2724 16.1548 15.3181 16.2603 15.3955 16.3411L19.9304 20.8761C20.0956 21.0413 20.3628 21.0413 20.528 20.8761L20.8761 20.528C21.0413 20.3628 21.0413 20.0956 20.8761 19.9304ZM10.3121 16.4993C6.89161 16.4993 4.12495 13.7327 4.12495 10.3121C4.12495 6.89161 6.89161 4.12495 10.3121 4.12495C13.7327 4.12495 16.4993 6.89161 16.4993 10.3121C16.4993 13.7327 13.7327 16.4993 10.3121 16.4993Z"
        fill={props?.fill || '#646464'}
      />
    </svg>
  )
}

export default SearchGlass
