import { useUI } from '@components/ui'
import Link from '@components/ui/Link'
import { ImageField } from '@prismicio/types'
import config from 'config/seo_meta.json'
import Image from 'next/legacy/image'
import { RichText, RichTextBlock } from 'prismic-reactjs'
import { FC } from 'react'

interface props {
  title: string
  link: string
  text: RichTextBlock[]
  image: ImageField
  handleCloseMegaMenu: Function
}

const NavFeaturedCallout: FC<props> = ({
  title,
  link,
  text,
  image,
  handleCloseMegaMenu,
}) => {
  const { closeSidebar } = useUI()
  const closeMenu = () => {
    handleCloseMegaMenu()
    closeSidebar()
  }
  return (
    <Link href={link} onClick={closeMenu}>
      <div className="space-y-2">
        {image.url && (
          <Image
            src={image.url}
            alt={image?.alt || config.openGraph.images[0].alt}
            height={256}
            width={192}
          />
        )}

        <div className="hover:opacity-75">
          <div className="body-3-bold uppercase text-dark-grey">{title}</div>
          <div className="body-2 text-dark-grey">{RichText.render(text)}</div>
        </div>
      </div>
    </Link>
  )
}
export default NavFeaturedCallout
