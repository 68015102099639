import { Cart } from '@commerce/types'
import UpsellItem from '@components/common/UpsellSideBar/UpsellItem/UpsellItem'
import { getUpsellProdutsByCart } from '@lib/cart'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
interface Props {
  cart: Cart.Cart
}

const CartUpsellItems = ({ cart }: Props) => {
  const { locale } = useRouter()
  const upsellProducts = getUpsellProdutsByCart(cart)
  const { t } = useTranslation('cart')
  if (upsellProducts.length === 0) return null

  return (
    <>
      <div className="label-bold pb-4 pt-8 capitalize">
        {`${t('recommended-for-you')}`}
      </div>
      <ul className="pb-8">
        {upsellProducts.map((handle: string, index: number) => (
          <UpsellItem
            handle={handle}
            key={`${handle}-cart-${locale}`}
            index={index}
            cart={cart}
          />
        ))}
      </ul>
    </>
  )
}

export default CartUpsellItems
