const ChevronDown = ({ ...props }) => {
  return (
    <svg
      width={12}
      height={7}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m11.905.286-.19-.191a.323.323 0 0 0-.458 0L6.002 5.353.743.095a.323.323 0 0 0-.457 0l-.191.19a.323.323 0 0 0 0 .458L5.77 6.421c.127.127.331.127.457 0L11.903.743a.32.32 0 0 0 .002-.457Z"
        fill={props?.fill || '#646464'}
      />
    </svg>
  )
}

export default ChevronDown
