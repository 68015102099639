const Logo = ({ ...props }) => {
  return (
    <svg
      width="102"
      height="24"
      viewBox="0 0 102 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2380_253)">
        <path
          d="M0.891602 0.00181637H7.71849C10.2336 -0.0398632 12.9922 0.619292 12.9922 3.68969C12.9922 5.008 12.2156 6.07932 11.0714 6.65666C12.6246 7.1105 13.585 8.46277 13.585 10.1377C13.585 13.6403 11.0301 14.7116 7.90229 14.7116H0.891602V0.00181637ZM4.69334 5.79065H7.63731C8.29135 5.79065 9.19047 5.44023 9.19047 4.38898C9.19047 3.33773 8.4338 2.96724 7.63731 2.96724H4.69334V5.79065ZM4.69334 11.6412H7.7384C8.94387 11.6412 9.78325 11.209 9.78325 9.99257C9.78325 8.69433 8.88413 8.26209 7.7384 8.26209H4.69334V11.6412Z"
          fill={props?.fill || '#646464'}
        />
        <path
          d="M18.5938 0.00183105H22.4614L27.9189 14.7116H23.9487L23.0496 12.075H17.9597L17.0407 14.7116H13.1348L18.5938 0.00183105ZM18.8389 9.23463H22.1551L20.536 4.08026H20.4947L18.8389 9.23463Z"
          fill={props?.fill || '#646464'}
        />
        <path
          d="M40.4037 8.63255C40.4037 12.8962 38.4615 15.0188 34.0471 15.0188C29.6327 15.0188 27.6904 12.8962 27.6904 8.63255V0.000244141H31.4983V8.85793C31.4983 10.7536 32.1937 11.7215 34.0532 11.7215C35.9127 11.7215 36.6081 10.7536 36.6081 8.85793V0.000244141H40.4099L40.4037 8.63255Z"
          fill={props?.fill || '#646464'}
        />
        <path
          d="M56.7714 14.7101H52.9742L50.9708 4.57419H50.9294L48.9259 14.7101H45.0629L41.1172 0.000244141H44.9189L46.9837 10.7536H47.025L49.1695 0.000244141H52.746L54.8904 10.7536H54.9333L56.9981 0.000244141H60.7998L56.7714 14.7101Z"
          fill={props?.fill || '#646464'}
        />
        <path
          d="M61.5996 0.00183105H73.23V3.07068H65.4013V5.64556H72.556V8.61716H65.4013V11.4143H73.4352V14.7101H61.5996V0.00183105Z"
          fill={props?.fill || '#646464'}
        />
        <path
          d="M74.5781 0.00183105H82.0192C84.5941 0.00183105 87.129 1.17658 87.129 4.142C87.129 5.72892 86.3724 7.22938 84.8315 7.82988V7.87155C86.3846 8.24204 86.835 9.99413 86.9575 11.3943C86.9989 12.0117 87.0601 14.1729 87.5702 14.7116H83.8175C83.4897 14.2177 83.4284 12.7759 83.3886 12.3637C83.286 10.8802 83.0409 9.35658 81.2626 9.35658H78.3799V14.7132H74.5781V0.00183105ZM78.3814 6.38807H81.5704C82.7146 6.38807 83.3288 5.7706 83.3288 4.6576C83.3288 3.5446 82.4696 3.07068 81.4081 3.07068H78.3829V6.38807H78.3814Z"
          fill={props?.fill || '#646464'}
        />
        <path
          d="M88.3145 0.00183105H92.1162V5.6471L96.9197 0.00183105H101.642L96.2457 5.79066L102.316 14.7116H97.5936L93.6694 8.53688L92.1162 10.1794V14.7116H88.3145V0.00183105Z"
          fill={props?.fill || '#646464'}
        />
        <path
          d="M4.92908 19.2531C4.82952 18.5322 4.2735 18.1509 3.49845 18.1509C2.30677 18.1509 1.84879 19.213 1.84879 20.2735C1.84879 21.334 2.30831 22.3961 3.49845 22.3961C4.36234 22.3961 4.86015 21.8959 4.92908 21.0747H6.77634C6.67678 22.9178 5.33499 23.9984 3.53675 23.9984C1.39234 23.9984 0 22.3467 0 20.2735C0 18.2003 1.3908 16.5486 3.53675 16.5486C5.06847 16.5486 6.72579 17.5304 6.77634 19.2516H4.92908V19.2531Z"
          fill={props?.fill || '#646464'}
        />
        <path
          d="M10.742 16.5502C12.9768 16.5502 14.239 18.2019 14.239 20.2751C14.239 22.3483 12.9768 24 10.742 24C8.50726 24 7.24512 22.356 7.24512 20.2751C7.24512 18.1942 8.50572 16.5502 10.742 16.5502ZM10.742 22.3977C11.9337 22.3977 12.3917 21.3356 12.3917 20.2751C12.3917 19.2146 11.9322 18.1525 10.742 18.1525C9.55189 18.1525 9.09237 19.2146 9.09237 20.2751C9.09237 21.3356 9.54883 22.3977 10.742 22.3977Z"
          fill={props?.fill || '#646464'}
        />
        <path
          d="M14.9937 16.7H16.841V22.2464H20.1495V23.8488H14.9922V16.7H14.9937Z"
          fill={props?.fill || '#646464'}
        />
        <path
          d="M24.0467 16.5502C26.2815 16.5502 27.5436 18.2019 27.5436 20.2751C27.5436 22.3483 26.2815 24 24.0467 24C21.8119 24 20.5498 22.3483 20.5498 20.2751C20.5498 18.2019 21.8104 16.5502 24.0467 16.5502ZM24.0467 22.3977C25.2384 22.3977 25.6964 21.3356 25.6964 20.2751C25.6964 19.2146 25.2369 18.1525 24.0467 18.1525C22.8566 18.1525 22.3971 19.2146 22.3971 20.2751C22.3971 21.3356 22.8535 22.3977 24.0467 22.3977Z"
          fill={props?.fill || '#646464'}
        />
        <path
          d="M34.429 20.8957C34.429 22.9689 33.4854 24.0001 31.3395 24.0001C29.1935 24.0001 28.25 22.9689 28.25 20.8957V16.7H30.0973V21.0053C30.0973 21.9315 30.4358 22.3946 31.3395 22.3946C32.2432 22.3946 32.5817 21.9238 32.5817 21.0053V16.7H34.429V20.8957Z"
          fill={props?.fill || '#646464'}
        />
        <path
          d="M35.4131 16.7H39.0295C40.2809 16.7 41.5139 17.2711 41.5139 18.7067C41.5139 19.4786 41.1463 20.2088 40.4019 20.499V20.519C41.157 20.6996 41.3761 21.5502 41.4358 22.231C41.4557 22.5397 41.4848 23.5833 41.7345 23.8426H39.9056C39.7525 23.6018 39.7172 22.9009 39.6973 22.7018C39.6483 21.9809 39.5288 21.2399 38.6634 21.2399H37.2619V23.8426H35.4131V16.7ZM37.2603 19.8043H38.8104C39.3665 19.8043 39.6651 19.5033 39.6651 18.963C39.6651 18.4227 39.2485 18.1912 38.7308 18.1912H37.2603V19.8028V19.8043Z"
          fill={props?.fill || '#646464'}
        />
        <path
          d="M43.9049 17.0194C44.1988 17.0219 44.4854 17.112 44.7286 17.2786C44.9717 17.4451 45.1604 17.6805 45.2709 17.955C45.3813 18.2296 45.4086 18.531 45.3492 18.8211C45.2898 19.1113 45.1465 19.3772 44.9372 19.5853C44.728 19.7933 44.4622 19.9343 44.1736 19.9902C43.8849 20.0462 43.5862 20.0147 43.3153 19.8997C43.0444 19.7847 42.8134 19.5914 42.6514 19.3442C42.4894 19.0969 42.4038 18.8069 42.4053 18.5106C42.4053 18.3131 42.4442 18.1175 42.5198 17.9353C42.5954 17.7531 42.7062 17.5878 42.8457 17.449C42.9852 17.3103 43.1507 17.2009 43.3325 17.1271C43.5143 17.0534 43.7089 17.0168 43.9049 17.0194ZM43.9049 19.7981C44.157 19.8067 44.406 19.7393 44.6199 19.6045C44.8339 19.4697 45.0031 19.2737 45.1059 19.0415C45.2087 18.8093 45.2405 18.5514 45.1971 18.3009C45.1537 18.0504 45.0372 17.8187 44.8625 17.6353C44.6877 17.4519 44.4626 17.3252 44.216 17.2714C43.9694 17.2176 43.7125 17.2391 43.4781 17.3333C43.2437 17.4274 43.0426 17.5899 42.9003 17.7999C42.758 18.0099 42.6811 18.2579 42.6795 18.5122C42.6731 18.6783 42.7 18.8441 42.7588 18.9995C42.8175 19.1549 42.9067 19.2967 43.0211 19.4165C43.1356 19.5363 43.2728 19.6316 43.4247 19.6966C43.5765 19.7616 43.7399 19.7951 43.9049 19.795V19.7981ZM43.3243 17.6369H43.9968C44.4103 17.6369 44.6094 17.8005 44.6094 18.1371C44.6094 18.4736 44.4103 18.5863 44.1499 18.6141L44.6462 19.3859H44.3644L43.8911 18.6326H43.5985V19.3859H43.3289V17.64L43.3243 17.6369ZM43.5939 18.4087H43.8757C44.1147 18.4087 44.3353 18.3964 44.3353 18.1201C44.3353 17.887 44.1361 17.8515 43.9539 17.8515H43.5985V18.4087H43.5939Z"
          fill={props?.fill || '#646464'}
        />
      </g>
      <defs>
        <clipPath id="clip0_2380_253">
          <rect width="102.4" height="24" fill={props?.fill || '#646464'} />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Logo
