import { Check, Close } from '@components/icons'
import WYSIWYG from '@components/ui/WYSIWYG'
interface Props {
  onClose?: () => void
  prismicContent?: any
}
const SurfaceDetailsModal = ({ onClose, prismicContent }: Props) => {
  const { surfaceSelectionModalTitle, surfaceSelectionModalText, slices } =
    prismicContent?.data || {}

  return (
    <>
      <div className="py-6 px-8 md:py-10">
        <div className="flex w-full justify-end">
          <button onClick={onClose}>
            <Close />
          </button>
        </div>
        {surfaceSelectionModalTitle && (
          <h4 className="heading-4">{surfaceSelectionModalTitle}</h4>
        )}
        {surfaceSelectionModalText && (
          <div className="body-1 pt-6 text-center">
            <WYSIWYG content={surfaceSelectionModalText} />
          </div>
        )}
        {slices && (
          <div className="flex flex-col gap-y-4 pt-6 md:pt-8">
            {slices.map((surface: any) => {
              const { description, text } = surface.primary

              return (
                <div key={`surface-${description}`} className="bg-white p-4">
                  {text && (
                    <div className="body-1-bold mb-2 self-stretch">{text}</div>
                  )}
                  {surface?.primary?.description && (
                    <div className="pb-6">
                      {description.map((description: any, index: number) => {
                        const descriptionText = [description]
                        return (
                          <div className="flex gap-2 pb-2" key={index}>
                            <div className="h-5 w-5">
                              <Check />
                            </div>
                            {description?.text && (
                              <div className="body-2 text-brand-dark-grey">
                                <WYSIWYG content={descriptionText} />
                              </div>
                            )}
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        )}
      </div>
    </>
  )
}
export default SurfaceDetailsModal
