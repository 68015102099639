import MegaMenu from '@components/common/MegaMenu'
import SidebarLayout from '@components/common/SidebarLayout'
import { ChevronRight, FancyArrowLeft } from '@components/icons'
import { useUI } from '@components/ui/context'
import { groupNavItems } from '@lib/helpers/group-nav-items'
import { useRouter } from 'next/router'
import { useState } from 'react'

export default function MenuSidebarView({
  menuItems = [],
  onClose,
}: {
  menuItems?: any
  onClose: () => void
}) {
  const { closeSidebar } = useUI()
  const router = useRouter()
  const groupedMenuItems = groupNavItems(menuItems?.data?.slices)
  const [showMegaMenu, setShowMegaMenu] = useState(false)
  const [megaMenuIndex, setMegaMenuIndex] = useState(-1)
  const hideMegaMenu = () => {
    setMegaMenuIndex(-1)
    setShowMegaMenu(false)
  }
  const setMegaMenuVisibility = (value: boolean) => {
    setShowMegaMenu(value)
  }
  return (
    <SidebarLayout handleClose={onClose}>
      <div className="pt-8 pb-10 bg-brand-light-grey">
        <nav>
          {!showMegaMenu && (
            <ul className="space-y-8 pl-4 pr-6">
              {groupedMenuItems.map((nav, index) => {
                const openMegaMenu = () => {
                  if (nav.link) {
                    closeSidebar()
                    router.push(nav.link)
                  } else {
                    setMegaMenuIndex(index)
                    setShowMegaMenu(true)
                  }
                }
                return (
                  <li
                    className="body text-ui-dark-grey flex justify-between"
                    key={nav.label}
                    onClick={openMegaMenu}
                  >
                    <div>{nav.label}</div>
                    <div className="mt-1">
                      <ChevronRight />
                    </div>
                  </li>
                )
              })}
            </ul>
          )}
          {showMegaMenu && (
            <div>
              <div className="relative flex wrapper">
                <button onClick={hideMegaMenu}>
                  <FancyArrowLeft />
                </button>
                <div className="mx-auto body-3-bold text-ui-dark-grey uppercase">
                  {groupedMenuItems[megaMenuIndex]?.label}
                </div>
              </div>
              <MegaMenu
                handleMegaMenuVisility={setMegaMenuVisibility}
                menuItems={groupedMenuItems[megaMenuIndex]?.items}
              />
            </div>
          )}
        </nav>
      </div>
    </SidebarLayout>
  )
}

MenuSidebarView
