import { CheckCircle } from '@components/icons'
import { useUI } from '@components/ui'
import s from './CartNotification.module.css'

const CartNotification = () => {
  const { cartNotificationMessage } = useUI()
  return (
    <div className={s.root}>
      <CheckCircle />
      <div className="body-2 text-primary ml-3">{cartNotificationMessage}</div>
    </div>
  )
}
export default CartNotification
