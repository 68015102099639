const Menu = ({ ...props }) => {
  return (
    <svg
      width={18}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.759 2.346H.24A.241.241 0 0 1 0 2.106V1.14A.241.241 0 0 1 .241.9H17.76a.241.241 0 0 1 .241.241v.964a.241.241 0 0 1-.241.241Zm0 6.429H.24A.241.241 0 0 1 0 8.534V7.57a.241.241 0 0 1 .241-.241H17.76a.241.241 0 0 1 .241.24v.965a.241.241 0 0 1-.241.241Zm0 6.429H.24A.241.241 0 0 1 0 14.962v-.964a.241.241 0 0 1 .241-.24H17.76a.24.24 0 0 1 .241.24v.965a.241.241 0 0 1-.241.24Z"
        fill={props?.fill || '#646464'}
      />
    </svg>
  )
}

export default Menu
