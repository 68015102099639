import { formatPrice } from '@commerce/product/use-price'
import { Cart } from '@commerce/types'
import type { ContextualizeProduct, Product } from '@commerce/types/product'
import Price from '@components/common/Price/Price'
import { useUI } from '@components/ui'
import { checkUpsellProductExistsByVariant } from '@lib/cart'
import cn from 'clsx'
import Image from 'next/image'
import { useRouter } from 'next/router'
import UpsellButton from './UpsellButton'

const placeholderImg = '/product-img-placeholder.svg'

interface PrepCoatUpsellCardsProps {
  product: Product
  b2bProduct: ContextualizeProduct | null
  handleUpsellSelection?: (itemType: string) => void
  addingItemIds: string[]
  setAddingItemIds: React.Dispatch<React.SetStateAction<string[]>>
  cart?: Cart.Cart
}

const PrepCoatUpsellCards = ({
  product,
  b2bProduct,
  handleUpsellSelection,
  addingItemIds,
  setAddingItemIds,
  cart,
}: PrepCoatUpsellCardsProps) => {
  const { selectedLiters } = useUI()
  const { locale } = useRouter()
  let iterations

  if (selectedLiters === null) {
    iterations = product?.variants?.length // Default to full length
  } else if (selectedLiters < 4) {
    iterations = 1
  } else if (selectedLiters >= 4 && selectedLiters < 10) {
    iterations = 2
  }

  const variantsSubset = product?.variants?.slice(0, iterations)

  return (
    <>
      {variantsSubset.map((item, index) => {
        // check if variant id already exists in the cart
        const variantId = item.id
        const isVaritantExists =
          cart && checkUpsellProductExistsByVariant(cart, variantId)

        if (isVaritantExists && isVaritantExists.length > 0) return null

        const b2bItem = b2bProduct?.variants?.find((v) => v.id === item.id)
        const price = item?.price
        const b2bPrice = b2bItem?.price
        const retailPrice = price && b2bPrice && price > b2bPrice ? price : null

        const formattedRetailPrice = retailPrice
          ? formatPrice({
              amount: retailPrice,
              currencyCode: product?.price.currencyCode!,
              locale,
            })
          : null  

        return (
          <li
            className={cn('border-x border-ui-grey-25 bg-white', {
              'border-y': index === 0,
              'border-b': index !== 0,
              'pointer-events-none opacity-50': addingItemIds.includes(
                String(item?.id)
              ),
            })}
            key={index}
          >
            <div className="flex items-center gap-3 p-3">
              <div className="relative aspect-square w-16">
                <Image
                  src={item?.image?.src || placeholderImg}
                  fill
                  alt={item?.image?.altText || 'upsell item'}
                />
              </div>
              <div className="basis-36">
                <div className="body-2-bold">{item?.name}</div>
                <div className="flex">
                  {retailPrice ? (
                    <del className="body-2 mr-1">{formattedRetailPrice}</del>
                  ) : null}
                  <Price
                    price={b2bPrice || item?.price}
                    currencyCode={product?.price.currencyCode}
                    prefix={false}
                    className="body-2"
                  />
                </div>
              </div>
              <UpsellButton
                variantId={String(item?.id)}
                handleUpsellSelection={handleUpsellSelection}
                itemType="prepCoat"
                formattedRetailPrice={formattedRetailPrice}
                setAddingItemIds={setAddingItemIds}
              />
            </div>
          </li>
        )
      })}
    </>
  )
}

export default PrepCoatUpsellCards
