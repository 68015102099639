const ChevronRight = ({ ...props }) => {
  return (
    <svg
      width={8}
      height={13}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m1.028 12.164-.191-.191a.323.323 0 0 1 0-.458L6.095 6.26.837 1a.323.323 0 0 1 0-.457l.191-.191a.323.323 0 0 1 .457 0l5.678 5.675a.323.323 0 0 1 0 .458L1.485 12.16a.32.32 0 0 1-.457.003Z"
        fill="#646464"
      />
    </svg>
  )
}

export default ChevronRight
