import commerceConfig from '@commerce/config';
import processLocale from '@lib/locale';
import Cookies from 'js-cookie';

const setCartId = (locale: string | undefined, cartId: string) => {
  const { config: { shopifyCookieExpiry, context } } = commerceConfig;
  const currentLocale = processLocale(locale);
  const currentContext = context?.[currentLocale];

  const options = { expires: shopifyCookieExpiry }
  Cookies.set(currentContext?.cartIdCookieName, cartId, options)
}

export default setCartId
