const Location = ({ ...props }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.66699 3.83337C6.28848 3.83337 5.16699 4.95486 5.16699 6.33337C5.16699 7.71189 6.28848 8.83337 7.66699 8.83337C9.04551 8.83337 10.167 7.71189 10.167 6.33337C10.167 4.95486 9.04551 3.83337 7.66699 3.83337ZM7.66699 8.00004C6.74798 8.00004 6.00033 7.25238 6.00033 6.33337C6.00033 5.41436 6.74798 4.66671 7.66699 4.66671C8.586 4.66671 9.33366 5.41436 9.33366 6.33337C9.33366 7.25238 8.586 8.00004 7.66699 8.00004ZM7.66699 1.33337C4.90556 1.33337 2.66699 3.57194 2.66699 6.33337C2.66699 8.34934 3.36934 8.91231 7.15314 14.3977C7.40142 14.7563 7.93254 14.7564 8.18087 14.3977C11.9646 8.91231 12.667 8.34934 12.667 6.33337C12.667 3.57194 10.4284 1.33337 7.66699 1.33337ZM7.66699 13.6753C4.03952 8.42942 3.50033 8.01291 3.50033 6.33337C3.50033 5.22041 3.93374 4.17408 4.72072 3.3871C5.5077 2.60012 6.55402 2.16671 7.66699 2.16671C8.77996 2.16671 9.82629 2.60012 10.6133 3.3871C11.4002 4.17408 11.8337 5.22041 11.8337 6.33337C11.8337 8.0128 11.2948 8.42895 7.66699 13.6753Z"
        fill={props?.fill || '#646464'}
      />
    </svg>
  )
}

export default Location
