import { Slice } from '@prismicio/types'

export const groupNavItems = (items: Slice[]) => {
  const navItems: any[] = []
  let navIndex = -1
  if (items && items.length) {
    items.forEach((item, index) => {
      if (item.slice_type === 'nav_item') {
        navIndex = index
        const navItem: any = {
          label: item.primary.Label,
          link: item.primary.Link,
          type: item.primary.Type,
          items: [],
        }
        if (item.primary.Type === 'Link') {
          navItems.push(navItem)
        } else {
          for (let i = index + 1; i < items.length; i++) {
            if (items[i].slice_type === 'nav_item') break
            navItem.items.push({
              type: items[i].slice_type,
              title: items[i].primary.Title,
              links: items[i].items,
              link: items[i].primary.Link,
              description: items[i].primary.Description,
              image: items[i].primary.image,
            })
          }
          navItems.push(navItem)
        }
      }
    })
  }

  return navItems
}
