import { gql } from '@apollo/client'
const getProductPriceQuery = /* GraphQL */ gql`
  query getProductPriceBySlug(
    $slug: String!
    $country: CountryCode!
    $language: LanguageCode!
    $buyer: BuyerInput
  ) @inContext(country: $country, language: $language, buyer: $buyer) {
    productByHandle(handle: $slug) {
      id
      handle
      priceRange {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      compareAtPriceRange {
        minVariantPrice {
          amount
          currencyCode
        }
      }
      variants(first: 250) {
        edges {
          node {
            id
            price {
              amount
              currencyCode
            }
            compareAtPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`

export default getProductPriceQuery
