const FancyArrowLeft = ({ ...props }) => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.257 7.5c.629.341 1.163.726 1.622 1.184.951.95 1.532 2.171 2.009 3.836a.5.5 0 1 1-.962.275c-.455-1.588-.974-2.624-1.754-3.403C3.39 8.608 2.294 8.038.593 7.475A.5.5 0 0 1 .25 7a.5.5 0 0 1 .343-.477C2.294 5.96 3.39 5.39 4.173 4.606c.78-.78 1.298-1.815 1.753-3.403a.5.5 0 0 1 .962.275C6.41 3.142 5.83 4.363 4.879 5.313A6.92 6.92 0 0 1 3.252 6.5H17.25a.5.5 0 0 1 0 1H3.257Z"
        fill="#646464"
      />
    </svg>
  )
}

export default FancyArrowLeft
