import client from '@commerce/client'
import { getCurrencyCountryCode } from '@commerce/utils'
import cartBuyerIdentityUpdate from '@commerce/utils/mutations/cart-buyer-identity-update'

const updateCartBuyerIdentity = async (
  locale: string,
  cartId: string,
  storefrontCustomerAccessToken?: string,
  companyLocationId?: string
) => {
  let buyerIdentity: any = { countryCode: getCurrencyCountryCode(locale), customerAccessToken: null, companyLocationId: null }
  let mutateOptions = {
    mutation: cartBuyerIdentityUpdate,
    context: {
      locale,
    },
    variables: {
      cartId: cartId,
      buyerIdentity: buyerIdentity,
      country: getCurrencyCountryCode(locale),
    },
  }
  if (storefrontCustomerAccessToken && companyLocationId) {
    buyerIdentity.customerAccessToken = storefrontCustomerAccessToken
    buyerIdentity.companyLocationId = companyLocationId
  }
  const { data } = await client.mutate(mutateOptions)

  const updatedCart =
    data?.cartBuyerIdentityUpdate?.cart

  return updatedCart
}

export default updateCartBuyerIdentity
