import { useQuery } from '@apollo/client'
import type { LineItem } from '@commerce/types/cart'
import {
  getCartId,
  getCartQuery,
  getCurrencyCountryCode,
  getLanguage,
  normalizeCart,
} from '@commerce/utils'
import { useArkadeList } from '@components/arkade/list/context'
import CountrySelectorButton from '@components/common/CountrySelectorButton'
import { Account, Cart, Heart, SearchGlass } from '@components/icons'
import Link from '@components/ui/Link'
import { useUI } from '@components/ui/context'
import useGetList from '@lib/arkade/list/hooks/useGetList'
import useGetLists from '@lib/arkade/list/hooks/useGetLists'
import { List } from '@lib/arkade/list/types'
import useCustomer from '@lib/hooks/useCustomer'
import cn from 'clsx'
import { useRouter } from 'next/router'
import React from 'react'
import s from './UserNav.module.css'

const countItem = (count: number, item: LineItem) => count + item.quantity

const UserNav: React.FC<{
  className?: string
  iconsColour?: string
}> = ({ className, iconsColour }) => {
  const { locale } = useRouter()
  const cartId = getCartId(locale)
  const { setSidebarView, openSidebar, closeSidebar } = useUI()
  const { data, loading } = useQuery(getCartQuery, {
    variables: {
      cartId,
      country: getCurrencyCountryCode(locale),
      language: getLanguage(locale),
    },
    context: { locale },
    skip: !cartId,
  })
  const cart = data?.cart ? normalizeCart(data.cart) : null
  const itemsCount = cart?.lineItems.reduce(countItem, 0) ?? 0
  const showSearchBar = () => {
    setSidebarView('SEARCH_VIEW')
    openSidebar()
  }
  const showWishListSidebar = () => {
    setSidebarView('WISH_LIST_SIDEBAR_VIEW')
    openSidebar()
  }
  const { customer } = useCustomer()
  const { listOwnerEmail, tempListId } = useArkadeList()

  const { data: list } = useGetList(tempListId, locale)
  const { listItem } = list || {}
  const { data: userLists } = useGetLists(listOwnerEmail, locale)
  function hasNonEmptyListItem(data: List[]): boolean {
    if (!Array.isArray(data)) return false
    for (const list of data) {
      if (list.listItem.length > 0) {
        return true
      }
    }
    return false
  }
  return (
    <nav className={cn(s.root, className)}>
      <ul className={s.list}>
        <li className="hidden lg:block">
          <CountrySelectorButton
            className="label-large"
            iconsColour={iconsColour}
          />
        </li>
        <li className={s.item}>
          <button type="button" onClick={showSearchBar}>
            <SearchGlass fill={iconsColour} />
          </button>
        </li>
        <li className={s.item}>
          <button
            type="button"
            className="relative"
            onClick={showWishListSidebar}
          >
            <Heart fill={iconsColour} stroke="white" />
            {(listItem?.length > 0 || hasNonEmptyListItem(userLists)) && (
              <span className="circle absolute top-0 right-0 h-[6px] w-[6px] rounded-full" />
            )}
          </button>
        </li>
        <li className={s.item}>
          {customer ? (
            <Link href="/me">
              <Account fill={iconsColour} />
              {customer && <span className={cn(s.circle, 'circle')}></span>}
            </Link>
          ) : (
            <Link href="/me/login">
              <Account fill={iconsColour} />
            </Link>
          )}
        </li>
        <li className={s.item}>
          <button
            className="relative focus:outline-none"
            onClick={() => {
              closeSidebar()
              setSidebarView('CART_VIEW')
              openSidebar()
              //toggleSidebar()
            }}
          >
            <Cart fill={iconsColour} />
            {itemsCount > 0 && <span className={cn(s.circle, 'circle')}></span>}
          </button>
        </li>
      </ul>
      <style jsx>
        {`
          .circle {
            background-color: ${iconsColour};
          }
        `}
      </style>
    </nav>
  )
}

export default UserNav
