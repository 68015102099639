import CountrySelectorButton from '@components/common/CountrySelectorButton'
import { Logo, PaymentOptions } from '@components/icons'
import { Container } from '@components/ui'
import SocialIcons from '@components/ui/SocialIcons'
import WYSIWYG from '@components/ui/WYSIWYG'
import cn from 'clsx'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, Fragment } from 'react'
import SubscribeForm from '../SubscribeForm'
import s from './Footer.module.css'
interface Props {
  className?: string
  footer?: any
}
interface footerItems {
  items: footerItem[]
  primary: {
    Heading: string
  }
}
interface footerItem {
  Link?: string
  LinkTitle?: string
}
interface bottomLink {
  link?: string
  linkTitle?: string
}
const Footer: FC<Props> = ({ className, footer }) => {
  const { asPath, locale } = useRouter()
  const { t } = useTranslation('common')
  const footerItems = footer?.data?.slices
  const bottomLinks = footer?.data?.bottomLinks
  const footerText = footer?.data?.footerText
  const rootClassName = cn(s.root, className)
  let footerBGColour = '#fff'

  if (asPath === '/')
    footerBGColour = footer?.data?.homepageBrushColour || '#fff'
  if (asPath.includes('/me')) footerBGColour = '#f5f5f5'

  return (
    <footer className={rootClassName}>
      <div className="w-full bg-brand-grey">
        <Container>
          <div className="grid grid-cols-2 py-10 lg:grid-cols-6 lg:gap-3 lg:py-12">
            <div className="hidden lg:block">
              <div className="pb-4">
                <Logo />
              </div>
              <SocialIcons />
            </div>
            {footerItems?.map((item: footerItems, index: number) => {
              return (
                <div key={`footer-items-${index}`}>
                  <div className="body-3-bold first: row-span-2 pt-8 pb-1 uppercase text-brand-dark-grey last:col-span-1 lg:pt-0">
                    {item.primary.Heading}
                  </div>
                  {item?.items.map((navItem: any, index: number) => {
                    const titles = navItem.LinkTitle?.split('~')
                    return (
                      <Link
                        key={`footer-item-link-${index}`}
                        href={navItem?.Link || '/'}
                        legacyBehavior
                      >
                        <a
                          href={navItem?.Link || '/'}
                          className=" mt-4 block cursor-pointer hover:opacity-75"
                        >
                          {titles.map((title: any, index: number) => {
                            return (
                              <div
                                key={`footer-item-link-title-${index}`}
                                className="body-2 text-brand-dark-grey "
                              >
                                {title}
                                <br />
                              </div>
                            )
                          })}
                        </a>
                      </Link>
                    )
                  })}
                </div>
              )
            })}
            <div className="body-2 col-span-2 row-start-1 text-brand-dark-grey lg:col-span-2 lg:col-start-5">
              <div className="grid grid-cols-1">
                <div className="heading-4">{t('colourful-emails')}</div>
                <div className="body-1 mt-2 mb-4 lg:mt-3">
                  {t('sign-up-receive-colour')}
                </div>
                <div className="pb-4">
                  <SubscribeForm key={locale} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center pb-6 lg:flex-row lg:pb-0">
            <div className="order-1 mr-auto lg:order-1 lg:mr-0">
              <CountrySelectorButton className="label text-brand-dark-grey" />
            </div>
            <div className="body-2 order-5 text-brand-dark-grey lg:order-2 lg:ml-7">
              © {new Date().getFullYear()} Bauwerk Colour Australia Pty Ltd
            </div>
            <div className="body-2 order-2 flex w-auto shrink flex-wrap items-center gap-y-4 overflow-hidden py-6 text-brand-dark-grey lg:order-3 lg:ml-8 lg:gap-y-0">
              {bottomLinks?.map((bottomLink: bottomLink, index: number) => {
                return (
                  <Fragment key={`bottom-links-${index}`}>
                    {bottomLink?.link ? (
                      <div className="flex items-center justify-center">
                        <Link href={bottomLink?.link}>
                          <div className="body-2 block cursor-pointer text-brand-dark-grey hover:opacity-75">
                            {bottomLink?.linkTitle}
                          </div>
                        </Link>
                        {index !== bottomLinks?.length - 1 && (
                          <div className="mx-2">|</div>
                        )}
                      </div>
                    ) : null}
                  </Fragment>
                )
              })}
            </div>
            <div className="order-3 block pt-2 pb-6 lg:order-4 lg:hidden">
              <SocialIcons />
            </div>
            <div className="order-4 pb-6 lg:order-5 lg:ml-auto lg:pb-0">
              <PaymentOptions locale={locale} />
            </div>
          </div>
          {footerText && (
            <div className="border-t-2 p-4 text-center text-body2 font-light">
              <div className="py-4 text-body2 font-light lg:mx-20">
                <WYSIWYG content={footerText} />
              </div>
            </div>
          )}
        </Container>
      </div>
      <style jsx>
        {`
          .footerBrushContainer {
            background-color: ${footerBGColour};
          }
        `}
      </style>
    </footer>
  )
}
export default Footer
