import { List } from "../types"


export default async function getUserTempListId(
  email: string,
  locale?: string,
) {

  //First Get/lists with X-Email to generate the verified list if not exist 
  try {
    const endPoint = `/api/list?email=${email}&locale=${locale}`
    await fetch(endPoint, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
  } catch (err) {
    console.log(err)
  }

  //Then get all the list and return listId that don't have name (tempListId)
  try {
    const endPoint = `/api/list?email=${email}&locale=${locale}&multipleList=true`
    const response = await fetch(endPoint, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },

    })
    if (response.status === 200) {
      const result: { lists: List[] } = await response.json()
      //Currently we assume the verified list is the temp list
      return result.lists?.find((list) => list.verified)?.id
    }
  } catch (err) {
    console.log(err)
  }

}
