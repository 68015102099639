import { gql } from '@apollo/client'

const cartCreateMutation = gql`
  mutation cartCreate($input: CartInput, $country: CountryCode!) @inContext(country: $country) {
    cartCreate(input: $input) {
      cart {
        id
        checkoutUrl
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`
export default cartCreateMutation
