import { ChevronRight, Cross } from '@components/icons'
import cn from 'clsx'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
interface Props {
  onClose: () => void
  title?: string
}

const MobileProfileSidebar = ({ onClose, title }: Props) => {
  const { t } = useTranslation('common')
  const sidebarLinks: { label: string; link: string }[] = [
    { label: `${t('your-details')}`, link: '/me' },
    { label: `${t('orders')}`, link: '/me/orders' },
  ]
  const Router = useRouter()
  const handleLinkSelect = (index: number) => {
    Router.push(sidebarLinks[index].link)
    onClose()
  }
  const { asPath } = useRouter()
  return (
    <>
      <div className="h-auto px-6 pt-10 pb-4 ">
        <div
          onClick={onClose}
          className="absolute right-3 top-3 p-2 text-brand-dark-grey md:hidden"
        >
          <Cross />
        </div>
        <div className="label-bold pb-8 text-center uppercase">{title}</div>
        <div className="body-1 text-center">
          {sidebarLinks.map((link, index) => {
            const selectLink = () => handleLinkSelect(index)
            return (
              <div key={index} onClick={selectLink}>
                <Link
                  href={link?.link || '/'}
                  passHref
                  className={cn(
                    'body-1 flex items-center justify-between pb-6 text-brand-dark-grey',
                    {
                      ' body-1-bold text-brand-dark-grey':
                        asPath === link?.link,
                    }
                  )}
                >
                  {link?.label}
                  <ChevronRight />
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
export default MobileProfileSidebar
