import { useQuery, useReactiveVar } from '@apollo/client'
import { Product } from '@commerce/schema'
import {
  getCurrencyCountryCode,
  getLanguage,
  getProductPriceQuery,
  getProductQuery,
  normalizeContextualizeProduct,
  normalizeProduct,
} from '@commerce/utils'

import { storefrontCustomerAccessTokenVar } from '@commerce/client'
import { formatPrice } from '@commerce/product/use-price'
import { Cart } from '@commerce/types'
import { ContextualizeShopifyProduct } from '@commerce/types/product'
import Price from '@components/common/Price/Price'
import useCustomer from '@lib/hooks/useCustomer'
import cn from 'clsx'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useState } from 'react'
import PrepCoatUpsellCards from './PrepCoatUpsellCards'
import UpsellButton from './UpsellButton'
import UpsellItemLoader from './UpsellItemLoader'

const placeholderImg = '/product-img-placeholder.svg'

interface Props {
  handle: string
  index: number
  handleUpsellSelection?: (itemType: string) => void
  cart?: Cart.Cart
}

const UpsellItem = ({ handle, index, cart, handleUpsellSelection }: Props) => {
  const { locale } = useRouter()
  const [addingItemIds, setAddingItemIds] = useState<string[]>([])
  const { customerCompanyLocationId } = useCustomer()
  const storefrontCustomerAccessToken = useReactiveVar(
    storefrontCustomerAccessTokenVar
  )

  const { data, loading } = useQuery(getProductQuery, {
    variables: {
      slug: handle,
      country: getCurrencyCountryCode(locale),
      language: getLanguage(locale),
    },
    context: { locale },
  })

  const { data: b2bData, loading: b2bProductLoading } = useQuery(
    getProductPriceQuery,
    {
      variables: {
        slug: handle,
        country: getCurrencyCountryCode(locale),
        language: getLanguage(locale),
        buyer: {
          customerAccessToken: storefrontCustomerAccessToken,
          companyLocationId: customerCompanyLocationId,
        },
      },
      context: { locale },
      skip: !customerCompanyLocationId || !storefrontCustomerAccessToken,
      fetchPolicy: 'no-cache',
    }
  )

  const product = data?.productByHandle
    ? normalizeProduct(data.productByHandle as Product)
    : null

  const b2bProduct = b2bData?.productByHandle
    ? normalizeContextualizeProduct(
        b2bData.productByHandle as ContextualizeShopifyProduct
      )
    : null

  const retailPrice =
    b2bProduct?.price?.value &&
    product?.price?.value &&
    product?.price?.value > b2bProduct?.price?.value
      ? product.price.value
      : null

  const formattedRetailPrice = retailPrice
    ? formatPrice({
        amount: retailPrice,
        currencyCode: product?.price.currencyCode!,
        locale,
      })
    : null

  // console.log('b2bProduct', b2bProduct)
  // console.log('product', product)

  return (
    <>
      {loading && !product && b2bProductLoading && (
        <UpsellItemLoader title={`${handle}-${locale}}`} />
      )}

      {product?.handle === 'lime-wash-prep-coat' ? (
        <PrepCoatUpsellCards
          product={product}
          b2bProduct={b2bProduct}
          handleUpsellSelection={handleUpsellSelection}
          addingItemIds={addingItemIds}
          setAddingItemIds={setAddingItemIds}
          cart={cart}
        />
      ) : (
        <li
          className={cn('border-x border-ui-grey-25 bg-white', {
            'border-y': index === 0,
            'border-b': index !== 0,
            'pointer-events-none opacity-50': addingItemIds.includes(
              String(product?.variants?.[0]?.id)
            ),
          })}
        >
          <div className="flex items-center gap-3 p-3">
            <div>
              <Image
                src={product?.images?.[0]?.url || placeholderImg}
                width={64}
                height={64}
                alt={product?.images?.[0]?.altText || 'upsell item'}
              />
            </div>
            <div className="basis-36">
              <div className="body-2-bold">{product?.name}</div>
              <div className="flex">
                {retailPrice ? (
                  <del className="body-2 mr-1">{formattedRetailPrice}</del>
                ) : null}
                <Price
                  price={b2bProduct?.price?.value || product?.price?.value || 0}
                  currencyCode={product?.price.currencyCode}
                  prefix={false}
                  className="body-2"
                />
              </div>
            </div>
            <UpsellButton
              variantId={String(product?.variants?.[0]?.id)}
              formattedRetailPrice={formattedRetailPrice}
              handleUpsellSelection={handleUpsellSelection}
              itemType={'brush'}
              setAddingItemIds={setAddingItemIds}
            />
          </div>
        </li>
      )}
    </>
  )
}

export default UpsellItem
