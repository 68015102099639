interface Props {
  fill?: string
}
const FancyArrowRight = ({ fill }: Props) => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7429 7.50012C14.1141 7.84086 13.5797 8.22549 13.1207 8.68406C12.1696 9.6344 11.5891 10.8554 11.1123 12.5195C11.0363 12.785 11.1898 13.0618 11.4553 13.1379C11.7208 13.2139 11.9976 13.0604 12.0737 12.7949C12.5287 11.2067 13.0476 10.1707 13.8276 9.39145C14.6115 8.60819 15.7055 8.03741 17.4068 7.47511C17.6175 7.40546 17.7508 7.20915 17.75 6.99866C17.7508 6.78817 17.6175 6.59185 17.4068 6.52221C15.7055 5.9599 14.6115 5.38913 13.8276 4.60586C13.0476 3.82659 12.5287 2.79059 12.0737 1.20239C11.9976 0.93693 11.7208 0.78338 11.4553 0.85943C11.1898 0.93548 11.0363 1.21233 11.1123 1.4778C11.5891 3.14194 12.1696 4.36292 13.1207 5.31326C13.581 5.77314 14.1171 6.15866 14.7483 6.50012L0.75 6.50012C0.473858 6.50012 0.25 6.72398 0.25 7.00012C0.25 7.27626 0.473858 7.50012 0.75 7.50012L14.7429 7.50012Z"
        fill={fill || 'white'}
      />
    </svg>
  )
}

export default FancyArrowRight
