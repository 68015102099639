import { Cross } from '@components/icons'
import { useUI } from '@components/ui'
import WYSIWYG from '@components/ui/WYSIWYG'
interface Props {
  onClose?: () => void
}
const SurfaceSelectionModal = ({ onClose }: Props) => {
  const { surfaceSelectionModalContent } = useUI()
  const { helperModalTitle, helperModalText } =
    surfaceSelectionModalContent || {}
  return (
    <>
      <div className="max-w-152 pt-8 pb-7 px-6 ">
        <div
          onClick={onClose}
          className="md:hidden right-3 top-3 absolute p-2 text-brand-dark-grey"
        >
          <Cross />
        </div>
        {helperModalTitle && (
          <div className="label-bold uppercase text-center">
            {helperModalTitle}
          </div>
        )}
        {helperModalText && (
          <div className="body-1 pt-6">
            <WYSIWYG content={helperModalText} />
          </div>
        )}
      </div>
    </>
  )
}
export default SurfaceSelectionModal
