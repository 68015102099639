const CheckCircle = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="10" fill="white" />
      <path
        d="M17.4084 9.37389L10.8587 15.871C10.688 16.0404 10.4122 16.0393 10.2429 15.8685L7.12631 12.7268C6.95694 12.5561 6.95803 12.2803 7.12878 12.111L7.43797 11.8043C7.60872 11.6349 7.88445 11.636 8.05382 11.8068L10.557 14.3301L16.4883 8.44638C16.6591 8.27701 16.9348 8.2781 17.1042 8.44885L17.4108 8.75804C17.5802 8.92879 17.5791 9.20452 17.4084 9.37389Z"
        fill="#646464"
      />
    </svg>
  )
}

export default CheckCircle
