const ChevronUp = ({ ...props }) => {
  return (
    <svg
      width={12}
      height={7}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m11.905 6.23-.19.191a.323.323 0 0 1-.458 0L6.002 1.163.743 6.42a.323.323 0 0 1-.457 0l-.191-.19a.323.323 0 0 1 0-.458L5.77.095a.323.323 0 0 1 .457 0l5.676 5.678c.129.126.129.33.002.457Z"
        fill="#646464"
      />
    </svg>
  )
}

export default ChevronUp
