interface Props {
  height?: number
  width?: number
  fill?: string
}
const Facebook = ({ height, width, fill }: Props) => {
  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 8.04889C16 3.60361 12.4183 0 8 0C3.58172 0 0 3.60361 0 8.04889C0 12.0663 2.92547 15.3962 6.75 16V10.3755H4.71875V8.04889H6.75V6.27562C6.75 4.25837 7.94438 3.1441 9.77172 3.1441C10.6467 3.1441 11.5625 3.3013 11.5625 3.3013V5.28208H10.5538C9.56 5.28208 9.25 5.90257 9.25 6.53972V8.04889H11.4688L11.1141 10.3755H9.25V16C13.0745 15.3962 16 12.0663 16 8.04889Z"
        fill={fill || '#646464'}
      />
    </svg>
  )
}

export default Facebook
