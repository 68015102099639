/* eslint-disable react-hooks/exhaustive-deps */
import Link from '@components/ui/Link'
import cn from 'clsx'
import { FC, MouseEvent, useState } from 'react'

interface Props {
  children?: any
  menuItems?: any[] | undefined
  transparentHeader?: boolean
  handleMegaMenuVisility: (value: boolean) => void
  handleMegaMenuIndex: (index: number) => void
  currentVisibility: boolean
  currentIndex: number
  linkColor: string
  hasScrolled: boolean
}

const Navigation: FC<Props> = ({
  menuItems,
  transparentHeader,
  handleMegaMenuIndex,
  handleMegaMenuVisility,
  currentIndex,
  linkColor,
  hasScrolled,
}) => {
  const [navHovered, setNavHovered] = useState(false)
  const clientYLimit = hasScrolled ? 60 : 90
  return (
    <>
      <ul
        className={cn('label-large flex h-full', {
          'text-white': linkColor === 'white',
          'text-ui-dark-grey': linkColor === '#646464',
        })}
      >
        {menuItems?.map((nav, index) => {
          const setMegaNav = () => {
            if (!nav.link) {
              handleMegaMenuVisility(true)
              handleMegaMenuIndex(index)
            }
            setNavHovered(true)
          }
          const resetMegaNav = (e: MouseEvent) => {
            if (nav.link) setNavHovered(false)
            if (e.clientY < clientYLimit) {
              //alert(e.clientY)
              handleMegaMenuIndex(-1)
              handleMegaMenuVisility(false)
              setNavHovered(false)
            }
          }

          return (
            <li
              key={nav.label}
              className={cn('relative transition-[padding] duration-500', {
                'px-6 xl:px-9': !hasScrolled,
                'px-9': hasScrolled,
              })}
              onMouseOver={setMegaNav}
              onMouseLeave={resetMegaNav}
            >
              <div
                className={cn(
                  'mt-0.5 cursor-pointer transition-[padding] duration-500 hover:border-b-2 hover:border-ui-dark-grey',
                  {
                    'border-b-2 border-ui-dark-grey':
                      currentIndex === index && navHovered,
                    'py-4': hasScrolled,
                    'xl:py-9.5 py-8.5': !hasScrolled,
                  }
                )}
              >
                {nav?.link ? (
                  <Link href={nav.link}>{nav.label}</Link>
                ) : (
                  <div className="cursor-pointer">{nav.label}</div>
                )}
              </div>
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default Navigation
