import { UserNav } from '@components/common'
import { ChevronLeft, Close, Cross, Logo } from '@components/icons'
import { useUI } from '@components/ui'
import processLocale, { LOCALE_SWITCHER, REGION_OPTIONS } from '@lib/locale'
import cn from 'clsx'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC, ReactNode } from 'react'
import CountrySelectorButton from '../CountrySelectorButton'
import s from './SidebarLayout.module.css'

type ComponentProps = {
  className?: string
  itemsCount?: Number
  children?: ReactNode
} & (
  | { handleClose: () => any; handleBack?: never }
  | { handleBack: () => any; handleClose?: never }
)

const SidebarLayout: FC<ComponentProps> = ({
  children,
  className,
  itemsCount,
  handleBack,
  handleClose,
}) => {
  const { t } = useTranslation('common')
  const { sidebarView } = useUI()
  const { locale } = useRouter()
  const currentLocale = processLocale(locale)
  const currentItem = LOCALE_SWITCHER.find(
    (item) => item.value === currentLocale
  )!
  const currentRegion = REGION_OPTIONS.find(
    (opt) => opt.value === currentItem.region
  )!

  return (
    <div className={cn(s.root, className)}>
      {sidebarView === 'CART_VIEW' && (
        <div className="sticky top-0 z-10">
          <header className="z-10 flex w-full  items-center justify-start border-b border-b-ui-grey-25 bg-accent-0 py-4 px-6 lg:py-6">
            <div className="text-label font-bold text-brand-dark-grey">
              {t('your-cart')}
            </div>
            <div className="ml-2 text-label font-normal text-brand-dark-grey">
              {`${itemsCount} ${t('items')}`}
            </div>
            {handleClose && (
              <button
                onClick={handleClose}
                aria-label="Close"
                className="ml-auto transition duration-150 ease-in-out hover:text-accent-5 focus:outline-none"
              >
                <Cross className="h-5 w-5 text-brand-dark-grey hover:text-accent-3" />
              </button>
            )}
          </header>
          <header className="z-10 flex w-full items-center justify-start border-b border-b-ui-grey-25 bg-accent-0 px-6 py-4 text-brand-dark-grey">
            <div className="text-body2 font-light">{t('shipping-to')}:</div>
            <div className=" ml-2 text-body2 font-medium">
              {t(currentRegion.name.toLowerCase())}
            </div>
            <div className="ml-auto">
              <CountrySelectorButton displayText="Change" />
            </div>
          </header>
        </div>
      )}
      {sidebarView === 'MOBILEMENU_VIEW' && (
        <header className={s.header}>
          {handleClose && (
            <button
              onClick={handleClose}
              aria-label={`${t('close')}`}
              className="mr-6 flex items-center transition duration-150 ease-in-out hover:text-accent-5 focus:outline-none"
            >
              <Cross className="hover:text-accent-3" />
              <Logo className="ml-3" />
            </button>
          )}
          {handleBack && (
            <button
              onClick={handleBack}
              aria-label={`${t('go-back')}`}
              className="flex items-center transition duration-150 ease-in-out hover:text-accent-5 focus:outline-none"
            >
              <ChevronLeft className="h-6 w-6 hover:text-accent-3" />
              <span className="ml-2 text-xs text-accent-7">{t('back')}</span>
            </button>
          )}
          <UserNav iconsColour="#646464" />
        </header>
      )}
      {sidebarView === 'SHARE_WISH_LIST_SIDEBAR_VIEW' && (
        <div className="sticky top-0 z-10 flex justify-center border-b border-ui-grey-25 bg-white py-6 ">
          <div className="label-bold m-auto text-center text-dark-grey">
            {t('share-your-list')}
          </div>
          <div
            onClick={handleClose}
            className="absolute right-6 top-6 cursor-pointer self-end"
          >
            <Close />
          </div>
        </div>
      )}
      {sidebarView === 'SHARE_ARTICLE_SIDEBAR_VIEW' && (
        <div className="sticky top-0 z-10 flex justify-center border-b border-ui-grey-25 bg-white py-6 ">
          <div className="label-bold m-auto text-center text-dark-grey">
            {t('share-this-article')}
          </div>
          <div
            onClick={handleClose}
            className="absolute right-6 top-6 cursor-pointer self-end"
          >
            <Close />
          </div>
        </div>
      )}
      {(sidebarView === 'CREATE_NEW_LIST_SIDEBAR_VIEW' ||
        sidebarView === 'CREATE_NEW_LIST_ONLY_SIDEBAR_VIEW' ||
        sidebarView === 'POST_CREATE_NEW_LIST_SIDEBAR_VIEW' ||
        sidebarView === 'POST_MOVE_LIST_ITEM_SIDEBAR_VIEW' ||
        sidebarView === 'POST_DUPLICATE_LIST_ITEM_SIDEBAR_VIEW') && (
        <div className="sticky top-0 z-10 flex justify-center border-b border-ui-grey-25 bg-white py-6 ">
          <div className="label-bold m-auto text-center text-dark-grey">
            {t('create-new-list')}
          </div>
          <div
            onClick={handleClose}
            className="absolute right-6 top-6 cursor-pointer self-end"
          >
            <Close />
          </div>
        </div>
      )}
      {/* {sidebarView === 'CREATE_NEW_LIST_ONLY_SIDEBAR_VIEW' && (
        <div className="sticky top-0 z-10 flex justify-center border-b border-ui-grey-25 bg-white py-6 ">
          <div className="label-bold m-auto text-center text-dark-grey">
            {t('create-new-list')}
          </div>
          <div
            onClick={handleClose}
            className="absolute right-6 top-6 cursor-pointer self-end"
          >
            <Close />
          </div>
        </div>
      )}
      {sidebarView === 'POST_CREATE_NEW_LIST_SIDEBAR_VIEW' && (
        <div className="sticky top-0 z-10 flex justify-center border-b border-ui-grey-25 bg-white py-6 ">
          <div className="label-bold m-auto text-center text-dark-grey">
            {t('create-new-list')}
          </div>
          <div
            onClick={handleClose}
            className="absolute right-6 top-6 cursor-pointer self-end"
          >
            <Close />
          </div>
        </div>
      )} */}
      <div className={s.container}>{children}</div>
    </div>
  )
}

export default SidebarLayout
