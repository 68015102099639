import cn from 'clsx'
import { motion } from 'framer-motion'
import { useEffect, useRef } from 'react'
import { lock, unlock } from 'tua-body-scroll-lock'
import { useUI } from '../context'
import s from './Sidebar.module.css'

interface SidebarProps {
  children: any
  onClose: () => void
  slideInFromClassName: string
  classNames?: string
  transparentBody?: boolean
}

const Sidebar: React.FC<SidebarProps> = ({
  children,
  onClose,
  slideInFromClassName,
  classNames,
  transparentBody = false,
}) => {
  const sidebarRef = useRef() as React.MutableRefObject<HTMLDivElement>
  const contentRef = useRef() as React.MutableRefObject<HTMLDivElement>
  const { sidebarIsClosing, sidebarView, setListItemActionData } = useUI()

  const handleClose = () => {
    onClose()
  }

  const onKeyDownSidebar = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'Escape') {
      onClose()
    }
  }

  useEffect(() => {
    const contentElement = contentRef.current
    if (contentElement && sidebarView !== 'SEARCH_VIEW') {
      lock(contentElement)
    }
    return () => {
      unlock(contentElement)
    }
  }, [sidebarView])
  const slideInAnimationVariants = {
    hidden: (
      slideInFromClassName:
        | 'slideInFromRight'
        | 'slideInFromLeft'
        | 'slideInFromBottom'
        | 'slideInFromTop'
    ) => {
      switch (slideInFromClassName) {
        case 'slideInFromRight':
          return {
            x: '420px',
          }
        case 'slideInFromLeft':
          return {
            x: '-420px',
          }
        case 'slideInFromBottom':
          return {
            y: '100vh',
          }
        case 'slideInFromTop':
          return {
            y: '-100vh',
          }
      }
    },
    visible: (
      slideInFromClassName:
        | 'slideInFromRight'
        | 'slideInFromLeft'
        | 'slideInFromBottom'
        | 'slideInFromTop'
    ) => {
      switch (slideInFromClassName) {
        case 'slideInFromRight':
          return {
            x: '0',
          }
        case 'slideInFromLeft':
          return {
            x: '0',
          }
        case 'slideInFromBottom':
          return {
            y: '0',
          }
        case 'slideInFromTop':
          return {
            y: '0',
          }
      }
    },
  }
  return (
    <motion.div
      className={cn(s.root)}
      ref={sidebarRef}
      onKeyDown={onKeyDownSidebar}
      tabIndex={1}
    >
      <motion.div className="absolute inset-0 overflow-hidden">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
          exit={{ opacity: 0 }}
          className={s.backdrop}
          onClick={handleClose}
        />
        <motion.div
          variants={slideInAnimationVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          custom={slideInFromClassName}
          transition={{ duration: 0.4, type: 'tween' }}
          className={cn(
            'absolute flex outline-none',
            slideInFromClassName,
            { slideOut: sidebarIsClosing, slideIn: !sidebarIsClosing },
            {
              'inset-y-0 right-0': slideInFromClassName === 'slideInFromRight',
            },
            { 'inset-y-0 left-0': slideInFromClassName === 'slideInFromLeft' },
            {
              'bottom-0': slideInFromClassName === 'slideInFromBottom',
            },
            {
              'top-0': slideInFromClassName === 'slideInFromTop',
            },
            classNames
          )}
        >
          <div className="h-full w-full ">
            <div
              className={cn(s.sidebar, {
                'max-h-[90vh] overflow-y-auto rounded-t-2xl':
                  slideInFromClassName === 'slideInFromBottom',
                'bg-transparent': transparentBody,
                'bg-brand-light-grey shadow-xl': !transparentBody,
              })}
              ref={contentRef}
              id="sidebar-content"
            >
              {children}
            </div>
          </div>
        </motion.div>
      </motion.div>
    </motion.div>
  )
}

export default Sidebar
