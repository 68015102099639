import commerceConfig from '@commerce/config'
import usePrice from '@commerce/product/use-price'
import processLocale from '@lib/locale'
import cn from 'clsx'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
interface Props {
  prefix: boolean
  className?: string
  price: number
  listPrice?: number // this can be shopify compare at price or price in case of b2b customer
  currencyCode?: string
}

const Price = ({
  price,
  listPrice,
  currencyCode,
  prefix,
  className,
}: Props) => {
  const { locale } = useRouter()
  const { t } = useTranslation('common')
  const {
    config: { context },
  } = commerceConfig
  const currentLocale = processLocale(locale)
  const currentContext = context?.[currentLocale]
  const taxAmount = currentContext?.taxAmount
  const { price: formattedPrice } = usePrice({
    amount: price!,
    currencyCode: currencyCode!,
    suffix: `(${t('excl-vat')})`,
  })
  const { price: afterTaxFormattedPrice } = usePrice({
    amount: price! * (1 + taxAmount || 1),
    currencyCode: currencyCode!,
    suffix: `(${t('incl-vat-approx')})`,
  })
  const { price: formattedListPrice } = usePrice({
    amount: listPrice!,
    currencyCode: currencyCode!,
    suffix: `(${t('excl-vat')})`,
  })
  // const { price: afterTaxFormattedListPrice } = usePrice({
  //   amount: listPrice! * (1 + taxAmount || 1),
  //   currencyCode: currencyCode!,
  //   suffix: `(${t('incl-vat-approx')})`,
  // })
  return (
    <div className="flex items-center">
      <div className={cn(className)}>
        <span
          className={cn(' flex flex-col', {
            'text-sale': listPrice && listPrice > price,
          })}
        >
          <div className="flex gap-2">
            {prefix === true ? (
              <span className="capitalize">{t('from')}&nbsp;</span>
            ) : null}
            {price > 0
              ? formattedPrice
              : !price && !listPrice
              ? formattedPrice
              : t('free')}
            {locale === 'en-gb' && (
              <div className="label">({t('include-gst-vat')})</div>
            )}
          </div>

          {taxAmount && (
            <div>
              {price > 0
                ? afterTaxFormattedPrice
                : !price && !listPrice
                ? afterTaxFormattedPrice
                : t('free')}
            </div>
          )}
          {listPrice && listPrice > price ? (
            <div className={cn('body-2  flex gap-2')}>
              <span>Retail price</span>
              <span> {formattedListPrice}</span>
            </div>
          ) : null}
        </span>
      </div>
    </div>
  )
}

export default Price
