import { Close } from '@components/icons'
import WYSIWYG from '@components/ui/WYSIWYG'
import { PrismicDocument } from '@prismicio/types'
interface Props {
  onClose?: () => void
  prismicContent?: PrismicDocument
}
const GardenPotModal = ({ onClose, prismicContent }: Props) => {
  const { gardenPotPaintModalText, gardenPotPaintModalTitle } =
    prismicContent?.data || {}
  return (
    <>
      <div className="px-8 py-6 md:py-10">
        <div className="flex w-full justify-end">
          <button onClick={onClose}>
            <Close />
          </button>
        </div>
        {gardenPotPaintModalTitle && (
          <h4 className="heading-4">{gardenPotPaintModalTitle}</h4>
        )}
        {gardenPotPaintModalText && (
          <div className="body-1 flex flex-col gap-4 pt-8">
            <WYSIWYG content={gardenPotPaintModalText} />
          </div>
        )}
      </div>
    </>
  )
}
export default GardenPotModal
