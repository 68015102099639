interface Props {
  height?: number
  width?: number
  fill?: string
}
const Youtube = ({ height, width, fill }: Props) => {
  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.146 3.35116C15.3953 3.59935 15.575 3.90878 15.6669 4.24837C15.9934 5.49515 16 8.09796 16 8.09796C16 8.09796 16 10.7008 15.6669 11.9475C15.5742 12.2862 15.3942 12.5945 15.1449 12.8417C14.8956 13.0889 14.5857 13.2664 14.2464 13.3562C12.9996 13.6907 7.99671 13.6907 7.99671 13.6907C7.99671 13.6907 2.99515 13.6907 1.74837 13.3615C1.40945 13.2704 1.10042 13.0918 0.85226 12.8437C0.604096 12.5955 0.425512 12.2865 0.334403 11.9475C0 10.7008 0 8.09796 0 8.09796C0 8.09796 0 5.49515 0.334403 4.24837C0.425512 3.90945 0.604096 3.60042 0.85226 3.35226C1.10042 3.1041 1.40945 2.92551 1.74837 2.8344C2.99383 2.5 7.99671 2.5 7.99671 2.5C7.99671 2.5 12.9983 2.5 14.2464 2.8344C14.5864 2.92475 14.8966 3.10296 15.146 3.35116ZM10.5534 8.09797L6.39709 5.69922V10.4967L10.5534 8.09797Z"
        fill={fill || '#646464'}
      />
    </svg>
  )
}

export default Youtube
