import { CartNotification } from '@components/cart'
import { MobileNav, Navigation, UserNav } from '@components/common'
import { Logo } from '@components/icons'
import { Container } from '@components/ui'
import { useUI } from '@components/ui/context'
import { groupNavItems } from '@lib/helpers/group-nav-items'
import cn from 'clsx'
import throttle from 'lodash.throttle'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'

import MegaMenu from '../MegaMenu'
import NavbarRoot from './NavbarRoot'

interface Link {
  href: string
  label: string
}

interface NavbarProps {
  links?: Link[]
  navItems: any
}

const Navbar: FC<NavbarProps> = ({ links, navItems }) => {
  const { asPath: path } = useRouter()
  const groupedNavItems: any[] = groupNavItems(navItems?.data?.slices)
  const {
    toggleSidebar,
    closeSidebarIfPresent,
    openModal,
    setSidebarView,
    displayCartNotification,
  } = useUI()
  const [showMegaMenu, setShowMegaMenu] = useState(false)
  const [megaMenuIndex, setMegaMenuIndex] = useState(-1)
  const [hasScrolled, setHasScrolled] = useState(false)
  const colourPalette = {
    normal: { iconColour: '#646464', navBarBackgroundColour: '#F5F5F5' },
    white: { iconColour: '#646464', navBarBackgroundColour: 'white' },
    transparent: { iconColour: 'white', navBarBackgroundColour: 'transparent' },
  }
  const [iconsColour, setIconsColour] = useState(
    colourPalette.normal.iconColour
  )
  const [navBarBackgroundColour, setNavBarBackgroundColour] = useState(
    colourPalette.normal.navBarBackgroundColour
  )
  const [hoverOnMegaMenu, setHoverOnMegaMenu] = useState(false)
  const [hoverOnNavBar, setHoverOnNavBar] = useState(false)
  const onHomePage = path === '/'
  const onPressPage = path === '/presspage'
  const onColourLab = path === '/collection/colour-lab'
  const onProductPage = path.includes('/product')
  const useHomePageStyles = onHomePage || onPressPage
  const useColourLabStyles = onProductPage || onColourLab
  useEffect(() => {
    const handleScroll = throttle(() => {
      const offset = 0
      const { scrollTop } = document.documentElement
      const scrolled = scrollTop > offset

      if (hasScrolled !== scrolled) {
        setHasScrolled(scrolled)
      }
    }, 200)

    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [hasScrolled])
  useEffect(() => {
    if (useHomePageStyles) {
      if (hasScrolled) {
        setIconsColour(colourPalette.normal.iconColour)
        setNavBarBackgroundColour(colourPalette.normal.navBarBackgroundColour)
      } else {
        if (hoverOnMegaMenu || hoverOnNavBar) {
          setIconsColour(colourPalette.normal.iconColour)
          setNavBarBackgroundColour(colourPalette.normal.navBarBackgroundColour)
        } else {
          setIconsColour(colourPalette.transparent.iconColour)
          setNavBarBackgroundColour(
            colourPalette.transparent.navBarBackgroundColour
          )
        }
      }
    } else if (useColourLabStyles) {
      setIconsColour(colourPalette.white.iconColour)
      setNavBarBackgroundColour(colourPalette.white.navBarBackgroundColour)
    } else {
      setIconsColour(colourPalette.normal.iconColour)
      setNavBarBackgroundColour(colourPalette.normal.navBarBackgroundColour)
    }
  }, [
    hasScrolled,
    useHomePageStyles,
    hoverOnMegaMenu,
    hoverOnNavBar,
    colourPalette.normal.iconColour,
    colourPalette.normal.navBarBackgroundColour,
    colourPalette.transparent.iconColour,
    colourPalette.transparent.navBarBackgroundColour,
    onColourLab,
    colourPalette.white.iconColour,
    colourPalette.white.navBarBackgroundColour,
    useColourLabStyles,
  ])
  const hideMegaMenu = () => {
    setShowMegaMenu(false)
    setMegaMenuIndex(-1)
  }
  const setMegaMenuVisibility = (value: boolean) => {
    setShowMegaMenu(value)
  }
  const setMegaMenuIndexHandler = (index: number) => {
    setMegaMenuIndex(index)
  }
  const handleNavBarMouseOver = () => {
    if (onHomePage && window?.innerWidth >= 1024) {
      setIconsColour(colourPalette.normal.iconColour)
      setNavBarBackgroundColour(colourPalette.normal.navBarBackgroundColour)
      setHoverOnNavBar(true)
    }
  }
  const handleNavBarMouseOut = () => {
    if (onHomePage && window?.innerWidth >= 1024) {
      setIconsColour(colourPalette.transparent.iconColour)
      setNavBarBackgroundColour(
        colourPalette.transparent.navBarBackgroundColour
      )
      setHoverOnNavBar(false)
    }
  }
  return (
    <NavbarRoot>
      <div
        onMouseOver={handleNavBarMouseOver}
        onMouseOut={handleNavBarMouseOut}
        className={cn('navBar')}
      >
        <Container className="relative">
          <div className="flex justify-between">
            <button
              type="button"
              onClick={() => {
                setSidebarView('MOBILEMENU_VIEW')
                toggleSidebar()
              }}
              className="flex items-center py-4 pr-5 focus:outline-none lg:hidden"
            >
              <MobileNav iconsColour={iconsColour} />
            </button>
            <div
              className={cn('navItem transition-all duration-500 lg:my-auto', {
                'py-4': hasScrolled,
                'py-4 sm:py-6': !hasScrolled,
              })}
            >
              <Link href="/" legacyBehavior>
                <a className="border-0 " aria-label="Logo">
                  <div
                    className={cn('visible transition-all duration-500', {
                      'h-6 w-25.5 sm:h-8 sm:w-34': hasScrolled,
                      'h-6 w-25.5 sm:h-12 sm:w-51 lg:h-13 lg:w-55.25 xl:h-15 xl:w-63.75':
                        !hasScrolled,
                    })}
                  >
                    <Logo fill={iconsColour} width={'100%'} height={'100%'} />
                  </div>
                </a>
              </Link>
            </div>

            <div className="navItem hidden flex-1 align-middle lg:mx-auto lg:block">
              <Navigation
                menuItems={groupedNavItems}
                handleMegaMenuVisility={setMegaMenuVisibility}
                handleMegaMenuIndex={setMegaMenuIndexHandler}
                currentVisibility={showMegaMenu}
                currentIndex={megaMenuIndex}
                linkColor={iconsColour}
                hasScrolled={hasScrolled}
              />
            </div>
            <div className="navItem m-auto mr-0 align-middle">
              <UserNav iconsColour={iconsColour} />
            </div>
          </div>
          {displayCartNotification && <CartNotification />}
        </Container>
      </div>

      <div
        className={cn('mega-nav-transition absolute z-50 w-full ', {
          'mega-nav-transition-show':
            showMegaMenu && groupedNavItems[megaMenuIndex]?.type === 'MegaNav',
        })}
      >
        <div
          className={cn('h-screen bg-brand-dark-grey bg-opacity-25', {
            'h-screen':
              showMegaMenu &&
              groupedNavItems[megaMenuIndex]?.type === 'MegaNav',
          })}
        >
          <div onMouseLeave={hideMegaMenu}>
            <MegaMenu
              handleMegaMenuVisility={setMegaMenuVisibility}
              menuItems={groupedNavItems[megaMenuIndex]?.items}
              handleHoverOnMegaMenu={setHoverOnMegaMenu}
            />
          </div>
        </div>
      </div>

      <style jsx>
        {`
          .mega-nav-transition {
            visibility: hidden;
            transition: all 0.3s ease-in-out;
            opacity: 0;
          }
          .mega-nav-transition-show {
            visibility: visible;
            opacity: 100;
          }
          .navBar {
            transition-property: background-color;
            transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
            transition-duration: 350ms;
            background: ${navBarBackgroundColour};
          }
          @media (min-width: 1024px) {
            .navItem {
              display: flex;
              justify-content: center;
            }
            .navItem:first-child > span {
              margin-right: auto;
            }
            .navItem:last-child > span {
              margin-left: auto;
            }
          }
        `}
      </style>
    </NavbarRoot>
  )
}

export default Navbar
