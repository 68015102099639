import cn from 'clsx'
import throttle from 'lodash.throttle'
import { FC, ReactNode, useEffect, useState } from 'react'
import s from './Navbar.module.css'

interface Props {
  children: ReactNode
}

const NavbarRoot: FC<Props> = ({ children }) => {
  const [hasScrolled, setHasScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = throttle(() => {
      const offset = 0
      const { scrollTop } = document.documentElement
      const scrolled = scrollTop > offset

      if (hasScrolled !== scrolled) {
        setHasScrolled(scrolled)
      }
    }, 200)

    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [hasScrolled])

  return <div className={cn(s.root)}>{children}</div>
}

export default NavbarRoot
