import { Button, Input } from '@components/ui'
import ClickOutside from '@lib/click-outside'
import cn from 'clsx'
import { useRouter } from 'next/router'
import { FC, useCallback, useState } from 'react'
interface Props {}
const FeedbackWidget: FC<Props> = () => {
  const { asPath, locale } = useRouter()
  const [isOpen, setIsOpen] = useState(false)
  const [step, setStep] = useState(0)
  const [rating, setRating] = useState<string | null>(null)
  const [comment, setComment] = useState<string | null>(null)
  const [contactEmail, setContactEmail] = useState<string | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const emojis = [
    { value: 'Hate', emoji: '😡' },
    { value: 'Dislike', emoji: '😟' },
    { value: 'Neutral', emoji: '😐' },
    { value: 'Like', emoji: '😊' },
    { value: 'Love', emoji: '😍' },
  ]

  const toggleWidget = useCallback(() => setIsOpen((prev) => !prev), [])
  const handleSelectRating = useCallback(
    (value: string) => setRating(value),
    []
  )
  const handleClose = useCallback(() => {
    setIsOpen(false)
    setRating(null)
    setComment(null)
    setContactEmail(null)
    setIsSuccess(false)
    setTimeout(() => {
      setStep(0)
    }, 300)
  }, [])

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true)
    try {
      const response = await fetch('/api/feedback/submit', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          rating,
          comment,
          page_url: asPath,
          contact_email: contactEmail,
          locale,
        }),
      })
      const result = await response.json()
      setIsSuccess(result?.handle ?? false)
    } catch (err) {
      console.log(err)
    } finally {
      setIsSubmitting(false)
      setStep(3)
    }
  }, [rating, comment, asPath, contactEmail, locale])

  return (
    <ClickOutside active={isOpen} onClick={() => setIsOpen(false)}>
      <div
        className={cn(
          'fixed left-0 top-1/2 z-[9999] flex -translate-y-1/2 transform transition-all duration-300',
          {
            '-translate-x-[300px]': !isOpen,
          }
        )}
      >
        <div className="feedbackContent flex flex-col gap-y-4 rounded-r-lg bg-white p-5">
          {step === 0 && (
            <>
              <h2>How would you rate your experience?</h2>
              <div className="flex justify-between">
                {emojis.map((item) => (
                  <span
                    key={item.value}
                    onClick={() => handleSelectRating(item.value)}
                    className={cn('emoji', {
                      selected: rating === item.value,
                    })}
                    title={item.value}
                  >
                    {item.emoji}
                  </span>
                ))}
              </div>
              <div
                className="flex justify-between"
                style={{ fontSize: '0.8rem' }}
              >
                <span>Hate</span>
                <span>Love</span>
              </div>
              <Button
                className="w-1/4 self-end"
                variant="slim"
                disabled={!rating}
                onClick={() => setStep(1)}
              >
                Next
              </Button>
            </>
          )}
          {step === 1 && (
            <>
              <h2>Tell us about your experience...</h2>
              <Input placeholder="Please type here..." onChange={setComment} />
              <Button
                className="w-1/4 self-end"
                variant="slim"
                disabled={!comment}
                onClick={() => setStep(2)}
              >
                Next
              </Button>
            </>
          )}
          {step === 2 && (
            <>
              <h2 className="text-center">
                Leave your email if you're happy for us to contact you.
              </h2>
              <Input
                placeholder="Please type here..."
                onChange={setContactEmail}
              />
              <div className="flex gap-x-2 self-end">
                <Button
                  variant="naked"
                  className="h-[34px] underline"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  Skip
                </Button>
                <Button
                  className="self-end"
                  variant="slim"
                  disabled={!contactEmail || isSubmitting}
                  onClick={handleSubmit}
                >
                  Next
                </Button>
              </div>
            </>
          )}
          {step === 3 && (
            <>
              <h2 className="text-center">
                {isSuccess
                  ? 'Thank you for sharing your feedback with us!'
                  : 'There was an issue. Please try again later'}
              </h2>
              <Button
                className="w-1/3 self-center"
                variant="slim"
                onClick={handleClose}
              >
                Close
              </Button>
            </>
          )}
        </div>
        <button
          onClick={toggleWidget}
          className={cn(
            'body-2 absolute -right-[26px] top-1/2 flex -translate-y-1/2 rotate-180 flex-col rounded-tl-lg rounded-bl-lg bg-brand-white px-[3px] py-3 text-dark-grey transition-all duration-300 hover:opacity-100',
            {
              'opacity-50': !isOpen,
            }
          )}
        >
          <span style={{ writingMode: 'vertical-rl' }}>Feedback</span>
        </button>
        <style jsx>{`
          .feedbackContent {
            width: 300px;
            height: 100%;
            box-shadow: rgba(0, 0, 0, 0.13) 0px -5px 40px 0px;
          }
          .emoji {
            font-size: 2rem;
            cursor: pointer;
            transition: transform 0.2s;
            transform: scale(0.9);
            color: #000;
          }
          .emoji.selected {
            transform: scale(1.2);
            color: #ff6347;
          }
        `}</style>
      </div>
    </ClickOutside>
  )
}

export default FeedbackWidget
