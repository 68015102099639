import { ChevronDown, ChevronUp } from '@components/icons'
import { useUI } from '@components/ui/context'
import Link from '@components/ui/Link'
import cn from 'clsx'
import { FC, useState } from 'react'

interface props {
  title: string
  links: {
    Label: string
    Link: string
  }[]
  handleCloseMegaMenu: Function
}

const NavColumnList: FC<props> = ({ title, links, handleCloseMegaMenu }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const { closeSidebar } = useUI()

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const closeMenu = () => {
    handleCloseMegaMenu()
    closeSidebar()
  }

  return (
    <>
      <div className="hidden lg:block body-2-bold uppercase text-dark-grey mb-5">
        {title}
      </div>
      <button
        className="lg:hidden body-1 text-dark-grey mb-4 w-full flex justify-between focus:outline-none"
        onClick={toggleDrawer}
      >
        <div>{title}</div>
        <div className="lg:hidden mt-2">
          {isDrawerOpen ? <ChevronUp /> : <ChevronDown />}
        </div>
      </button>
      <ul className={cn('space-y-4', { 'hidden lg:block': !isDrawerOpen })}>
        {links.map((link) => (
          <li
            key={link.Label}
            className="body-1 text-dark-grey pl-6 lg:pl-0 hover:opacity-75"
            onClick={closeMenu}
          >
            <Link href={link.Link}>{link.Label}</Link>
          </li>
        ))}
      </ul>
    </>
  )
}

export default NavColumnList
