import { Cross } from '@components/icons'
import WYSIWYG from '@components/ui/WYSIWYG'
import cn from 'classnames'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import Marquee from 'react-fast-marquee'

interface Props {
  data: any
}

const AlertBar = ({ data }: Props) => {
  // Note: cookie base alert bar introuduce cumulative layout shift
  // const {
  //   acceptedCookies: alertBarCookie,
  //   onAcceptCookies: closeAlertBar,
  // } = useAcceptCookies('bauwerk_alert_bar', 'closed');
  const [closeBar, setCloseBar] = useState(false)
  const {
    Messages,
    TextColor,
    BackgroundColor,
    ScrollingSpeed,
    ShowCloseButton,
  } = data || {}

  const onCloseBox = () => {
    setCloseBar(true)
  }
  if (closeBar || !Messages) return <></>
  return (
    <div
      className={cn('alertBox h-10 p-2 text-13', {
        'flex w-full items-center justify-center text-center':
          Messages && Messages.length == 1,
      })}
    >
      {Messages.length === 1 ? (
        <WYSIWYG content={Messages[0]?.message} />
      ) : (
        <Marquee
          autoFill
          speed={ScrollingSpeed || 50}
          pauseOnHover={!isMobile}
          pauseOnClick={isMobile}
        >
          {Messages.map((item: any, index: number) => (
            <div className="px-32" key={index}>
              <WYSIWYG content={item?.message} />
            </div>
          ))}
        </Marquee>
      )}
      <style jsx>
        {`
          .alertBox {
            background: ${BackgroundColor || '#646464'};
            color: ${TextColor || '#FFFFFF'};
          }
        `}
      </style>
      <div
        className={cn('flex w-5 cursor-pointer items-center', {
          hidden: !ShowCloseButton,
        })}
        onClick={onCloseBox}
      >
        <Cross />
      </div>
    </div>
  )
}

export default AlertBar
