interface Props {
  height?: number
  width?: number
  fill?: string
}
const Pinterest = ({ height, width, fill }: Props) => {
  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1007_1285)">
        <path
          d="M8 0C3.58174 0 0 3.58174 0 8C0 11.3892 2.10866 14.2865 5.0846 15.4522C5.01469 14.8192 4.95147 13.8458 5.11233 13.1548C5.25772 12.5304 6.05043 9.17824 6.05043 9.17824C6.05043 9.17824 5.81114 8.699 5.81114 7.99049C5.81114 6.87809 6.4559 6.04761 7.25871 6.04761C7.94124 6.04761 8.2709 6.56004 8.2709 7.1745C8.2709 7.86091 7.83389 8.88714 7.60837 9.83815C7.4199 10.6344 8.00767 11.2838 8.79304 11.2838C10.2149 11.2838 11.3079 9.78451 11.3079 7.62037C11.3079 5.7049 9.93154 4.36566 7.96623 4.36566C5.68995 4.36566 4.35386 6.07299 4.35386 7.83743C4.35386 8.52502 4.61873 9.26231 4.94924 9.66313C5.01462 9.74234 5.0242 9.81173 5.00472 9.89258C4.944 10.1453 4.80904 10.6886 4.78254 10.7999C4.74766 10.9463 4.6666 10.9774 4.51498 10.9068C3.51584 10.4417 2.89114 8.98092 2.89114 7.80759C2.89114 5.28402 4.7247 2.96642 8.17706 2.96642C10.9523 2.96642 13.1089 4.944 13.1089 7.58686C13.1089 10.344 11.3705 12.5629 8.95764 12.5629C8.14696 12.5629 7.38488 12.1417 7.12401 11.6442C7.12401 11.6442 6.72287 13.1717 6.62561 13.546C6.44501 14.2409 5.95744 15.1119 5.63132 15.6433C6.37983 15.875 7.17529 16 8 16C12.4183 16 16.0001 12.4183 16.0001 8C16.0001 3.58174 12.4183 0 8 0Z"
          fill={fill || '#646464'}
        />
      </g>
      <defs>
        <clipPath id="clip0_1007_1285">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Pinterest
