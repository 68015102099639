const IDeal = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="24"
      fill="none"
      viewBox="0 0 38 24"
      {...props}
    >
      <g clipPath="url(#clip0_5250_96444)">
        <path
          fill="#fff"
          stroke="#EDEDED"
          d="M4 .5h30A3.5 3.5 0 0137.5 4v16a3.5 3.5 0 01-3.5 3.5H4A3.5 3.5 0 01.5 20V4A3.5 3.5 0 014 .5z"
        ></path>
        <path
          fill="#C06"
          d="M14 6.912V19h5.648C24.776 19 27 16.302 27 12.486 27 8.834 24.776 6 19.648 6h-4.67c-.543 0-.978.414-.978.912z"
        ></path>
        <path
          fill="#000"
          d="M19.312 21h-8.884C9.64 21 9 20.373 9 19.6V5.4c0-.773.64-1.4 1.428-1.4h8.884C27.742 4 29 9.317 29 12.482 29 17.974 25.555 21 19.313 21h-.001zM10.428 4.467a.944.944 0 00-.952.933v14.2a.936.936 0 00.952.934h8.884c5.938 0 9.212-2.86 9.212-8.052 0-6.972-5.774-8.015-9.212-8.015h-8.884z"
        ></path>
        <path
          fill="#fff"
          d="M16.252 11.008c.188 0 .361.03.528.088.167.06.304.155.427.273.116.125.21.28.282.457.065.184.101.398.101.649 0 .22-.028.42-.08.604-.05.174-.134.336-.245.479a1.196 1.196 0 01-.413.317c-.185.08-.385.12-.586.118H15V11h1.252v.008zm-.044 2.44a.918.918 0 00.276-.045.539.539 0 00.23-.155.863.863 0 00.168-.28c.043-.118.065-.25.065-.42 0-.147-.015-.287-.044-.405a.813.813 0 00-.145-.31.657.657 0 00-.26-.199 1.047 1.047 0 00-.398-.066h-.464v1.887h.572v-.008.001zm3.995-2.44v.553h-1.548v.64h1.426v.51h-1.426v.73h1.585v.552h-2.229V11h2.194v.008h-.002zm2.215 0l1.1 2.992h-.673l-.224-.663h-1.1l-.232.663h-.652l1.108-2.992h.673zm.037 1.835l-.37-1.098h-.007l-.384 1.098h.761zm2.112-1.835v2.44H26V14h-2.076v-2.992h.643z"
        ></path>
        <path
          fill="#000"
          d="M11.5 13.652c.829 0 1.5-.593 1.5-1.326 0-.732-.671-1.326-1.5-1.326s-1.5.594-1.5 1.326c0 .732.671 1.326 1.5 1.326zM12.63 19c-1.258 0-2.269-.9-2.269-2.007v-1.568a.969.969 0 01.337-.715c.214-.189.502-.294.802-.291a1.24 1.24 0 01.433.073c.137.05.262.124.368.218.106.093.19.205.248.327a.93.93 0 01.09.388V19H12.63z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_5250_96444">
          <path fill="#fff" d="M0 0H38V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  )
}

export default IDeal
