const Globe = ({ ...props }) => (
  <svg
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 3.131c-.894.223-1.778 1.042-2.494 2.433-.404.784-.737 1.721-.974 2.767H11.5v-5.2zM8.117 5.106c-.475.922-.853 2.016-1.108 3.225h-3.23a9.026 9.026 0 0 1 5.487-4.909 7.656 7.656 0 0 0-1.15 1.684zM6.83 9.331H3.402A8.998 8.998 0 0 0 3 12c0 .93.14 1.826.402 2.669h3.424a18.406 18.406 0 0 1-.19-2.656c0-.928.068-1.827.194-2.682zm.173 6.338H3.78a9.027 9.027 0 0 0 5.454 4.898 7.708 7.708 0 0 1-1.116-1.648c-.479-.928-.859-2.031-1.114-3.25zm1.023 0H11.5v5.225c-.894-.223-1.778-1.041-2.494-2.433-.408-.79-.743-1.736-.98-2.792zm3.474-1H7.837c-.13-.838-.2-1.73-.2-2.656 0-.937.072-1.837.204-2.682H11.5v5.338zm1 1v5.225c.894-.223 1.778-1.041 2.495-2.433.407-.79.742-1.736.979-2.792H12.5zm3.663-1H12.5V9.331h3.659c.133.845.205 1.745.205 2.682 0 .927-.07 1.818-.201 2.656zm.834 1c-.255 1.219-.635 2.322-1.113 3.25a7.712 7.712 0 0 1-1.117 1.648 9.027 9.027 0 0 0 5.454-4.898h-3.224zm3.601-1h-3.424c.124-.847.19-1.738.19-2.656 0-.928-.067-1.827-.194-2.682h3.428c.262.843.402 1.74.402 2.669 0 .93-.14 1.826-.402 2.669zm-3.606-6.338h3.23a9.026 9.026 0 0 0-5.488-4.909c.436.473.821 1.046 1.15 1.684.475.922.853 2.016 1.108 3.225zm-4.492 0h3.468c-.236-1.046-.57-1.983-.973-2.767-.717-1.392-1.6-2.21-2.495-2.433v5.2zM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12z"
    />
  </svg>
)

export default Globe
