const GooglePay = () => {
  return (
    <svg
      width="40"
      height="24"
      viewBox="0 0 40 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4890_101058)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V20C40 22.2091 38.2091 24 36 24H4C1.79086 24 0 22.2091 0 20V4Z"
          fill="white"
        />
        <path
          d="M0.5 4C0.5 2.067 2.067 0.5 4 0.5H36C37.933 0.5 39.5 2.067 39.5 4V20C39.5 21.933 37.933 23.5 36 23.5H4C2.067 23.5 0.5 21.933 0.5 20V4Z"
          stroke="black"
          strokeOpacity="0.07"
        />
        <path
          d="M19.3367 8.0348V10.8476H21.0799C21.4955 10.8476 21.8387 10.7088 22.1103 10.4312C22.3891 10.154 22.5287 9.8232 22.5287 9.4412C22.5287 9.066 22.3891 8.7396 22.1103 8.462C21.8387 8.1772 21.4955 8.034 21.0799 8.034H19.3367V8.0348ZM19.3367 11.838V15.1008H18.2959V7.0448H21.0579C21.7595 7.0448 22.3555 7.2776 22.8459 7.7424C23.3443 8.2072 23.5931 8.7736 23.5931 9.4412C23.5931 10.124 23.3443 10.694 22.8463 11.1512C22.3631 11.6088 21.7663 11.8372 21.0579 11.8372H19.3371V11.838H19.3367ZM24.6451 13.4128C24.6451 13.6828 24.7603 13.908 24.9907 14.088C25.2207 14.268 25.4907 14.358 25.7999 14.358C26.2375 14.358 26.6279 14.1968 26.9711 13.8744C27.3147 13.552 27.4859 13.1732 27.4859 12.7384C27.1615 12.4832 26.7087 12.356 26.1279 12.356C25.7047 12.356 25.3519 12.4576 25.0695 12.66C24.7867 12.8624 24.6455 13.1128 24.6455 13.4128M25.9927 9.4076C26.7623 9.4076 27.3695 9.612 27.8155 10.0208C28.2603 10.43 28.4827 10.9904 28.4827 11.7028V15.1008H27.4867V14.3356H27.4415C27.0111 14.9656 26.4375 15.2808 25.7211 15.2808C25.1099 15.2808 24.5979 15.1008 24.1871 14.7408C23.7759 14.3808 23.5703 13.9304 23.5703 13.3904C23.5703 12.82 23.7871 12.3668 24.2211 12.0296C24.6551 11.692 25.2339 11.5228 25.9583 11.5228C26.5767 11.5228 27.0863 11.6352 27.4863 11.8604V11.6236C27.4863 11.2636 27.3423 10.9584 27.0559 10.7068C26.7793 10.4594 26.4198 10.3249 26.0487 10.33C25.4675 10.33 25.0075 10.574 24.6679 11.062L23.7507 10.4876C24.2563 9.7676 25.0035 9.4076 25.9927 9.4076ZM34.1987 9.5876L30.7235 17.5308H29.6483L30.9387 14.7516L28.6523 9.5876H29.7843L31.4367 13.548H31.4595L33.0667 9.5876H34.1987Z"
          fill="#5F6368"
        />
        <path
          d="M15.1292 11.1344C15.1292 10.8084 15.1012 10.4944 15.0492 10.1932H10.6572V11.976H13.1724C13.1206 12.2612 13.0115 12.533 12.8517 12.7748C12.6918 13.0166 12.4846 13.2235 12.2424 13.3828V14.5408H13.7436C14.6228 13.7344 15.1292 12.5432 15.1292 11.1344Z"
          fill="#4285F4"
        />
        <path
          d="M10.6573 15.658C11.9141 15.658 12.9721 15.248 13.7437 14.5408L12.2425 13.3828C11.8245 13.6624 11.2865 13.8256 10.6573 13.8256C9.44249 13.8256 8.41129 13.0116 8.04289 11.9148H6.49609V13.1076C6.88613 13.8758 7.48152 14.5209 8.21611 14.9711C8.95069 15.4214 9.79571 15.6591 10.6573 15.658Z"
          fill="#34A853"
        />
        <path
          d="M8.0428 11.9148C7.84851 11.3401 7.84851 10.7175 8.0428 10.1428V8.95081H6.496C6.16923 9.59471 5.99928 10.3067 6 11.0288C6 11.7764 6.1792 12.4824 6.496 13.1072L8.0428 11.9144V11.9148Z"
          fill="#FABB05"
        />
        <path
          d="M10.6573 8.23203C11.3433 8.23203 11.9585 8.46683 12.4437 8.92683V8.92723L13.7729 7.60603C12.9657 6.85923 11.9129 6.40003 10.6569 6.40003C9.79538 6.39898 8.95044 6.63678 8.21593 7.087C7.48143 7.53723 6.8861 8.18225 6.49609 8.95043L8.04289 10.1432C8.41129 9.04643 9.44249 8.23243 10.6573 8.23243"
          fill="#E94235"
        />
      </g>
      <defs>
        <clipPath id="clip0_4890_101058">
          <rect width="40" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default GooglePay
