import { storefrontCustomerAccessTokenVar } from '@commerce/client'
import commerceConfig from '@commerce/config'
import processLocale from '@lib/locale'
import Cookies, { CookieAttributes } from 'js-cookie'

export const getStorefrontCustomerToken = (locale: string | undefined) => {
  const {
    config: { context },
  } = commerceConfig
  const currentLocale = processLocale(locale)
  const currentContext = context?.[currentLocale]

  return Cookies.get(currentContext.storefrontCustomerTokenCookieName)
}


// not used to set value as its happens in SSR
export const setStorefrontCustomerToken = (
  locale: string | undefined,
  token: string | null,
  options?: CookieAttributes
) => {
  const {
    config: { shopifyCookieExpiry, context },
  } = commerceConfig
  const currentLocale = processLocale(locale)
  const currentContext = context?.[currentLocale]

  if (!token) {
    Cookies.remove(currentContext.storefrontCustomerTokenCookieName)
    storefrontCustomerAccessTokenVar('')
  } else {
    Cookies.set(
      currentContext.storefrontCustomerTokenCookieName,
      token,
      options ?? {
        expires: shopifyCookieExpiry,
      }
    )
    storefrontCustomerAccessTokenVar(token)
  }
}
