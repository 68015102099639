import { useQuery, useReactiveVar } from '@apollo/client'
import commerceConfig from '@commerce/config'
import usePrice from '@commerce/product/use-price'

import updateCartBuyerIdentity from '@commerce/api/operations/update-cart-buyer-identity'
import { storefrontCustomerAccessTokenVar } from '@commerce/client'
import { Cart, LineItem } from '@commerce/types/cart'
import {
  getCartId,
  getCartQuery,
  getCurrencyCountryCode,
  getLanguage,
  normalizeCart,
} from '@commerce/utils'
import CheckoutButton from '@components/common/CheckoutButton/CheckoutButton'
import SidebarLayout from '@components/common/SidebarLayout'
import { Check, Cross, PaymentOptions } from '@components/icons'
import useCustomer from '@lib/hooks/useCustomer'
import processLocale, { getConfigByLocale } from '@lib/locale'
import cn from 'clsx'
import rules from 'config/cart_rules.json'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import CartItem from '../CartItem'
import CartSidebarMessage from './CartSidebarMessage'
import s from './CartSidebarView.module.css'
import CartUpsellItems from './CartUpsellItems'
interface Props {
  onClose: () => void
}

const countItem = (count: number, item: LineItem) => count + item.quantity
const CartSidebarView = ({ onClose }: Props) => {
  const { customer: customerData } = useCustomer()
  const { locale } = useRouter()
  const router = useRouter()
  const { t, i18n } = useTranslation('common')
  const {
    config: { context },
  } = commerceConfig
  const currentLocale = processLocale(locale)
  const currentContext = context?.[currentLocale]
  const taxAmount = currentContext?.taxAmount
  const cartId = getCartId(locale)
  const storefrontCustomerAccessToken = useReactiveVar(
    storefrontCustomerAccessTokenVar
  )

  const { data, loading, refetch } = useQuery(getCartQuery, {
    variables: {
      cartId,
      country: getCurrencyCountryCode(locale),
      language: getLanguage(locale),
    },
    context: { locale },
    skip: !cartId,
  })
  const cart = data?.cart ? normalizeCart(data.cart) : null
  const itemsCount = cart?.lineItems.reduce(countItem, 0) ?? 0
  const empty = !cart?.lineItems.length
  const { price: subTotal } = usePrice(
    cart && {
      amount: Number(cart.subtotalPrice),
      currencyCode: cart.currency.code,
      suffix: `(${t('excl-vat')})`,
    }
  )
  const { price: afterTaxSubTotal } = usePrice(
    cart && {
      amount: Number(cart.subtotalPrice) * (1 + taxAmount || 1),
      currencyCode: cart.currency.code,
      suffix: `(${t('incl-vat-approx')})`,
    }
  )
  const error = null
  const success = null
  let checkoutUrl = cart?.url
  const companyLocationId =
    customerData?.companyContacts?.edges?.[0]?.node?.company?.locations
      ?.edges?.[0]?.node?.id
  if (customerData)
    checkoutUrl = `/api/checkout?locale=${locale}&email=${customerData?.emailAddress?.emailAddress}`
  const algoliaIndex = getConfigByLocale(locale, 'algoliaProductIndex')
  const generateSearchStringFromCart = (cart: Cart) => {
    const searchStrings: string[] = []
    for (const lineItem of cart?.lineItems || []) {
      const handle = lineItem.path || ''
      searchStrings.push(`handle:${handle}`)
    }

    return searchStrings.join(' OR ')
  }
  useEffect(() => {
    const handleBuyerIdentityUpdate = async () => {
      await updateCartBuyerIdentity(
        locale!,
        cartId!,
        storefrontCustomerAccessToken,
        companyLocationId
      )
      await refetch()
    }
    handleBuyerIdentityUpdate()
  }, [
    cartId,
    companyLocationId,
    customerData,
    locale,
    refetch,
    storefrontCustomerAccessToken,
  ])

  return (
    <SidebarLayout
      key={router.locale}
      className={cn({
        [s.empty]: error || success || loading || empty,
      })}
      handleClose={onClose}
      itemsCount={itemsCount}
    >
      {loading || empty ? (
        <>
          <div className="body-1 pt-20 pb-4 text-center font-light text-brand-dark-grey">
            {t('cart-is-currently-empty')}
          </div>
          <div
            className="body-1 text-decoration-line: text-center font-light text-brand-dark-grey underline"
            onClick={onClose}
          >
            <Link href="/collection/colour-lab" passHref>
              {t('shop-range-of-colours')}
            </Link>
          </div>
        </>
      ) : error ? (
        <div className="flex flex-1 flex-col items-center justify-center px-4">
          <span className="flex h-16 w-16 items-center justify-center rounded-full border border-white">
            <Cross width={24} height={24} />
          </span>
          <h2 className="pt-6 text-center text-xl font-light">
            {t('couldnt-purchase-check-your-card')}
          </h2>
        </div>
      ) : success ? (
        <div className="flex flex-1 flex-col items-center justify-center px-4">
          <span className="flex h-16 w-16 items-center justify-center rounded-full border border-white">
            <Check />
          </span>
          <h2 className="pt-6 text-center text-xl font-light">
            {t('thank-for-your-order')}
          </h2>
        </div>
      ) : (
        <>
          <div className="flex-1 px-4 py-6 sm:px-6">
            {rules?.map((rule: any, index: number) => {
              return (
                <CartSidebarMessage
                  key={index}
                  lineItems={cart!.lineItems}
                  rule={rule}
                />
              )
            })}
            <ul className={s.lineItemsList}>
              {cart!.lineItems.map((item: any) => (
                <CartItem
                  key={item.id}
                  item={item}
                  currencyCode={cart!.currency.code}
                />
              ))}
            </ul>
            <CartUpsellItems cart={cart} />
          </div>

          <div className="sticky bottom-0 right-0 left-0 z-20 w-full flex-shrink-0 border-t bg-accent-0 px-6 py-6 text-sm sm:px-6">
            <ul className="pb-4">
              <li className="flex justify-between pb-2 text-label font-normal text-brand-dark-grey">
                <span>{t('subtotal')}</span>
                <div className="flex flex-col items-end">
                  <span className="text-label font-bold">{subTotal}</span>
                  {taxAmount && (
                    <span className="text-label font-bold">
                      {afterTaxSubTotal}
                    </span>
                  )}
                </div>
              </li>
              <li className="flex justify-between text-label font-normal text-brand-dark-grey">
                <span>{t('shipping')}</span>
                <span className="text-body2 font-light">
                  {t('caculated-checkout')}
                </span>
              </li>
              <li className="flex justify-end text-label font-normal text-brand-dark-grey">
                <span className="text-body2 font-light">
                  {i18n.exists('incl-vat-approx-detail') &&
                    `${t('incl-vat-approx-detail')}`}
                </span>
              </li>
            </ul>
            <CheckoutButton cart={cart} checkoutUrl={checkoutUrl} />
            <div className="flex justify-center pt-3">
              <PaymentOptions locale={locale} />
            </div>
          </div>
        </>
      )}
    </SidebarLayout>
  )
}

export default CartSidebarView
