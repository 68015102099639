import { Cross, Link as LinkIcon } from '@components/icons'
import WYSIWYG from '@components/ui/WYSIWYG'
import { PrismicDocument } from '@prismicio/types'
import Link from 'next/link'
interface Props {
  onClose?: () => void
  prismicContent?: PrismicDocument
}
const PaintSelectionModal = ({ onClose, prismicContent }: Props) => {
  const { paintSelectionModalTitle, paintSelectionModalText, links } =
    prismicContent?.data || {}
  return <>
    <div className="h-full max-w-152 pt-8 pb-7 px-6 ">
      <div
        onClick={onClose}
        className="md:hidden right-3 top-3 absolute p-2 text-brand-dark-grey"
      >
        <Cross />
      </div>
      {paintSelectionModalTitle && (
        <div className="label-bold uppercase text-center">
          {paintSelectionModalTitle}
        </div>
      )}
      {paintSelectionModalText && (
        <div className="body-1 flex flex-col gap-4 pt-6">
          <WYSIWYG content={paintSelectionModalText} />
        </div>
      )}
      {links && (
        <div className="pt-6 body-1 flex flex-col gap-4">
          {links.map((l: any, index: number) => {
            return (
              <Link key={index} href={l?.linkUrl} passHref legacyBehavior>
                <div
                  onClick={onClose}
                  className="flex cursor-pointer items-center gap-1 underline text-brand-dark-grey"
                >
                  {l?.linkTitle} <LinkIcon />
                </div>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  </>;
}
export default PaintSelectionModal
